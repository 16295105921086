import Lang from './modules/Lang';
import Currency from './modules/Currency';

const url = window.location.href.split('/');
const baseURL = url[0] + '//' + url[2];           //for example: http://hyperhost.com.ua
const baseURLLang = baseURL + '/' + url[3];       //for example: http://hyperhost.com.ua/ru

const lang = new Lang(baseURL); //create Lang obj

if (!getCookie('iso_code')) {
    let request = new XMLHttpRequest()

    request.onload = () => {
        let response = JSON.parse(request.response)
        let data = response[Object.keys(response)[0]];

        setCookie('iso_code', data.iso_code.toLowerCase(), { expires: 3, path: '/' })

        let curr = 'usd';

        if (data.country === 'Ukraine') {
            curr = 'uah';
        }

        if (data.country === 'Netherlands' || data.country === 'Poland' ||
            data.country === 'Denmark' || data.country === 'England' ||
            data.country === 'Estonia' || data.country === 'Finland' ||
            data.country === 'Iceland' || data.country === 'Ireland' ||
            data.country === 'Latvia' || data.country === 'Lithuania' ||
            data.country === 'Norway' || data.country === 'Scotland' ||
            data.country === 'Sweden' || data.country === 'Wales' ||
            data.country === 'Austria' || data.country === 'Belgium' ||
            data.country === 'France' || data.country === 'Germany' ||
            data.country === 'Switzerland' || data.country === 'Cyprus' ||
            data.country === 'Greece' || data.country === 'Italy' ||
            data.country === 'Malta' || data.country === 'Portugal' ||
            data.country === 'Slovenia' || data.country === 'Spain' ||
            data.country === 'Bulgaria' || data.country === 'Czech Republic' ||
            data.country === 'Hungary' || data.country === 'Moldova' ||
            data.country === 'Poland' || data.country === 'Romania' ||
            data.country === 'Slovakia' || data.country === 'Luxembourg' ||
            data.country === 'Liechtenstein'
        ) {
            curr = 'eur';
        }

        setCookie('currency', curr, { expires: 3, path: '/' })
        setCookie('geoip', true, { expires: 3, path: '/' })
    }

    request.onerror = () => {
        setCookie('currency', 'usd', { expires: 3, path: '/' })
    }

    request.open('POST', baseURL + '/geoip/json', false)
    request.setRequestHeader('X-CSRF-TOKEN', hp('meta[name="csrf-token"]').attr('content'))
    request.send()
}

let isoCode = !!getCookie('iso_code') ? 'ua' : getCookie('iso_code');

const curr = new Currency(lang, baseURL); //create Currency obj

const isCartPage = () => url.includes('cart');

export { url, baseURL, baseURLLang, lang, isoCode, curr, isCartPage }
