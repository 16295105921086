'use strict';

import { curr, baseURL, isCartPage, lang, baseURLLang } from "./varaibles";

import Stars from './modules/Stars'; //rating stars module

import { popupDontLeave } from './modules/popupDontLeave';
import { domains } from "./modules/Domains";

AdmitadLCW.init()

hp(window).on('load', () => {
    domains(lang, curr, baseURL, baseURLLang)
    Stars(baseURL)
    popupDontLeave.init()

    if (!isCartPage()) {
        curr.editCurrency();

        hp('.currency-block .list-item').each((currencyBtn, index) => {
            currencyBtn = hp(currencyBtn)

            if (currencyBtn.data('select-currency') == curr.getCurrency()) {
                curr.printTitleCurrency(currencyBtn.text());
            }

            currencyBtn.on('click', (e) => {
                hp('.currency-block .list-item').removeClass('active')

                currencyBtn.addClass('active')

                curr.printTitleCurrency(currencyBtn.text());
                curr.setCurrency(currencyBtn.data('select-currency'));
                curr.editCurrency();
            })
        })
    }

    // Scroll btn function start
    let scrollBtn = hp('#scroll-to-top-btn');
    scrollBtn.fadeTo(0)

    let progressSVG = hp('.progress-wrap svg');
    let progressCircle = progressSVG.find('.progress-circle');

    progressCircle.css({
        'stroke-dasharray': '156px',
        'stroke-dashoffset': '156px',
    });

    let getPercentage = (scrollPosition, maxScroll, maxClientScroll) => {
        return parseInt((scrollPosition / (maxScroll - maxClientScroll)) * 100);
    }

    let changeScrollLine = (percentage) => {
        percentage = 156 - (156 * (percentage / 100));

        progressCircle.css('stroke-dashoffset', `${percentage}px`)
    }

    hp(window).on('scroll', (e) => {
        const clientHeight = document.documentElement.clientHeight;
        const scrollHeight = document.documentElement.scrollHeight;
        const scrollTop = document.documentElement.scrollTop;

        let percentage = getPercentage(scrollTop, scrollHeight, clientHeight);

        changeScrollLine(percentage)

        if (percentage >= 5) {
            if (parseInt(scrollBtn.css('opacity')) <= 1) {
                scrollBtn.fadeTo(1)
            }

            return;
        }

        scrollBtn.fadeTo(0)
        return;
    });

    scrollBtn.on('click', (e) => {
        window.scrollTo({ top: 0 })
    })
})

