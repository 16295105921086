
function Currency(lang, baseURL) {
    this.lang = lang;
    this.baseURL = baseURL;
    this.currency_stor = {};

    this._getBillingCourse()

    if (localStorage.getItem('currency_stor') != null) {
        if (!this.checkCurrStorData()) {
            localStorage.removeItem('currency_stor')
        } else {
            this.currency_stor = JSON.parse(localStorage.getItem('currency_stor'));
        }
    }

    //set default currency for user
    if (getCookie('currency') == null || localStorage.getItem('currency_stor') == null) {
        //set cookies
        this.setCurrency('usd');

        // this._getBillingCourse()
    }
}

Currency.prototype = {
    _getBillingCourse: async function () {
        let request = new XMLHttpRequest()

        request.onload = () => {
            let response = JSON.parse(request.response)

            this.currency_stor = response;

            localStorage.setItem('currency_stor', JSON.stringify(this.currency_stor));
            this.editCurrency(getCookie('currency'));
        }

        request.onerror = () => {
            console.error(response);
        }

        request.open('POST', this.baseURL + '/currencyrate', true)
        request.setRequestHeader('X-CSRF-TOKEN', hp('meta[name="csrf-token"]').attr('content'))
        request.send()
    },

    editCurrency: function (currency = this.getCurrency()) {
        let courses = this.currency_stor;

        if (currency !== 'usd') {
            hp('span[data-currency]').each(span => {
                span = hp(span)

                let price = span.data('currency') * courses[currency]
                let price_show = price.toFixed(2).toString();

                span.html(parseInt(price_show) + price_show.substring(price_show.search(/[.]/i)));
            });
        } else {
            hp('span[data-currency]').each(span => {
                span = hp(span)

                let price = span.data('currency');
                let price_show = parseInt(price);
                let price_cut = parseFloat(price).toFixed(2).toString();

                span.html(price_show + price_cut.substring(price_cut.search(/[.]/i)));
            });
        }

        // add Active class to new currency
        hp('.currency-block .list-item[data-select-currency="' + currency + '"]').addClass('active');

        // Change watermarks
        hp('.watermark').text(this.lang.getInterpreter()[this.lang.getSiteLang()][currency]);
    },

    setCurrency: function (currency) {
        setCookie('currency', currency, { expires: 3, path: '/' }) //set cookies
    },

    getCurrency: function () {
        return getCookie('currency')
    },

    printTitleCurrency: function (currency) {
        hp('#currency-dropdown .selected').text(currency.toUpperCase());
    },

    setCartCurrency: function (currency) {
        hp('.cart .currency-block #' + currency).prop('checked', true)
    },

    checkCurrStorData: function () {
        let currency_stor = JSON.parse(localStorage.getItem('currency_stor'));


        return !!currency_stor.usd && !!currency_stor.uah && !!currency_stor.eur;
    }
}

module.exports = Currency;
