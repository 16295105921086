hp(window).on('load', () => {
    hp('[data-parent-dropdown]').each((elem, index) => {
        let dropdown = hp(elem)

        let mainSection = hp('#' + dropdown.data('parent-dropdown') + '_main_section');

        dropdown.find('[data-menu]').each((link, index) => {
            let section = hp('#' + hp(link).data('menu'));

            hp(link).hover(
                () => {
                    hp('.nav-menu-section.active').removeClass('active')

                    section.addClass('active')
                },
                () => {
                    if (hp('#' + hp(link).data('menu')).parent().find('.nav-menu-section.active').isEmpty()) {
                        section.parent().find('.nav-menu-section.active').removeClass('active')

                        mainSection.addClass('active')
                    }

                    dropdown.hover(() => { }, () => {
                        hp('[data-parent-dropdown] .main-section').each((targetSection, index) => {
                            section.parent().find('.nav-menu-section.active').removeClass('active')
                            hp(targetSection).addClass('active')
                            mainSection.addClass('active')
                        })
                    })
                }
            )
        })
    })


    let arrows = hp('.with-arrow');

    //We need change color of last triangle, because of right section bg
    let last_menu = arrows.elem.findLast(
        (element) => hp(element).hasClass('with-arrow') === true
    );
    hp(last_menu).addClass('additional-services-connector-color');



    let offcanvas_showing_buttons = document.querySelectorAll('.test-button-offcanvas');
    [...offcanvas_showing_buttons].map(elem => showOffCanvas(elem))

    let offcanvas_closing_buttons = document.querySelectorAll('.test-off-button-offcanvas');
    [...offcanvas_closing_buttons].map(elem => hideOffCanvas(elem))

    function hideOffCanvas(elem) {
        let target_name = elem.getAttribute('data-bs-dismiss')
        elem.addEventListener('click', () => {
            let elem = document.getElementById(target_name)
            elem.classList.add('hiding')
            setTimeout(() => {
                elem.classList.remove('hiding')
                elem.classList.remove('show')
            }, 500)
        })
    }

    function showOffCanvas(elem) {
        let target_name = elem.getAttribute('data-bs-target')

        elem.addEventListener('click', () => {
            let elem = document.getElementById(target_name)
            elem.classList.add('show')
        })
    }

    // let drop_down_func = function(elem) {
    //     let drop_down_menu = new bootstrap.Dropdown(elem, {display: "static", autoClose: "outside"});

    //     hp(elem).hover(
    //         () => {
    //             drop_down_menu.show();

    //             //add show class to button elem, to display triangle
    //             hp(elem).find('.with-arrow').addClass('show');
    //         },
    //         () => {
    //             drop_down_menu.hide();

    //             //remove show class from button elem, to display triangle
    //             hp(elem).find('.with-arrow').removeClass('show');

    //             if (!hp(elem).find('.dropdown-button').hasClass('show')) {
    //                 hp(elem).find('.nav-menu-section.active').removeClass('active');

    //                 hp('#' + hp(elem).data('parent-dropdown') + '_main_section').addClass('active');
    //             }
    //         }
    //     )
    // }

    // let drop_downs =  document.querySelectorAll('[data-parent-dropdown]');

    // [...drop_downs].map(elem => drop_down_func(elem));
});
