function Stars(baseURLLang) {
    let page = window.location.pathname.split('/').filter(function (val, index) {
        return index > 1
    }).join('/'); //get page segment url

    const ratingBlock = hp('#rating-block');

    if (ratingBlock.isEmpty()) {
        return;
    }

    const ratingInputs = ratingBlock.find('.star-input'); //rating block
    const ratingMSG = ratingBlock.find('.msg'); //rating block

    let data = {}; //rating data

    rating(ratingBlock)

    if (!page) {
        page = 'front'; //front page
    }

    //add default rating data
    data.ratings = ratingBlock.data('ratings');
    data.count = hp('[itemprop="ratingCount"]').text();
    data.page = page;

    ratingMSG.hide();
    if (getCookie('stars-' + page)) {
        ratingInputs.prop('disabled', true)
        ratingMSG.show();

        ratingBlock.data('readOnly', 'true')
    }

    ratingInputs.on('click', (e) => {
        if (ratingBlock.data('read-only') != 'true') {
            data.ratings = +((+data.ratings * +data.count + +ratingBlock.data('ratings')) / (+data.count + 1)).toFixed(6); //get form rating
            data.count = +data.count + 1; //get form count

            send();
            paintRating(data);
        }
    });


    function paintRating(data) {
        ratingBlock.find('[itemprop="ratingValue"]').text(+data['ratings'].toFixed(2));
        ratingBlock.find('[itemprop="ratingCount"]').text(+data['count']);
    }

    function send() {
        fetch(baseURLLang + '/generate-rating', {
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-TOKEN': hp('meta[name="csrf-token"]').attr('content')
            },
            body: JSON.stringify(data),
            method: 'POST'
        }).then(result => {
            if (result.ok) {
                return result.json()
            }

            return {
                result: 'error'
            }
        }).then((result) => {
            if ('result' in result && result.result == 'error') {
                return;
            }

            data = result;

            setCookie('stars-' + page, true, { expires: 356, path: '/' }); //set read only cookies from page
            ratingBlock.data('readOnly', 'true');
            ratingInputs.prop('disabled', true)
            ratingMSG.show()
            setTimeout(() => {
                ratingMSG.fadeOut();

                setTimeout(() => {
                    ratingMSG.hide();
                }, 3000)
            }, 3200)
        })
    }
}

module.exports = Stars;
