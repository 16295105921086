
hp(window).on('load', () => {
    if (document.querySelectorAll('.table-tab').length == 0) {
        return;
    }

    let tabs = hp('.table-tab');

    tabs.on('click', (e) => {
        let btn = hp(e.target);

        if (btn.hasClass('active')) {
            return;
        }

        hp('.table-tab.active').removeClass('active')
        btn.addClass('active');
    });
});

