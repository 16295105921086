require('../includes/modal-dialog')

function PopupDontLeave() {
    this.popup = hp('.retargeting');

    this.bootstrapModal = new bootstrap.Modal(this.popup.elem, {
        keyboard: false,
        backdrop: 'static'
    });
}

PopupDontLeave.prototype = {
    config: {
        delayInSeconds: 10,
        minScreenWidthForActivatePopupInPx: 1025,
        cookieName: 'popupDontLeave',
        cookieValue: 'showed',
        cookieTTLInSeconds: 1000 * 3 * 24 * 60 * 60,
        maxDistanceFromTopToShowPopupInPx: 5,
        eventName_popupShowed: "popupdontleave-showed",
        eventName_popupHidden: "popupdontleave-hidden"
    },

    isAllowedGeneral: function () {
        return this.isAllowedByElementsPresent() &&
               this.isAllowedByScreenSize() &&
               this.isAllowedByCookie();
    },

    // PopUp Modal Block Order Modal, so its disabled for cart
    IsNotCartPage: function () {
        let url = new URL(window.location);
        return !url.pathname.includes('cart')
    },

    isAllowedByElementsPresent: function () {
        return !this.popup.isEmpty();
    },

    isAllowedByScreenSize: function () {
        return window.matchMedia("(min-width: " + this.config.minScreenWidthForActivatePopupInPx + "px)").matches;
    },

    isAllowedByCookie: function () {
        return !(document.cookie.search(this.config.cookieName + '=' + this.config.cookieValue) + 1);
    },

    show: function () {
        if (this.isAllowedGeneral()) {
            this.bootstrapModal.show();
            this.generateEvent(this.config.eventName_popupShowed);
        }
    },

    hide: function () {
        this.bootstrapModal.hide();
        this.cookieSet();
        this.generateEvent(this.config.eventName_popupHidden);
    },

    generateEvent: function (eventName) {
        let event = new Event(eventName);
        document.dispatchEvent(event);
    },

    cookieSet: function () {
        setCookie(this.config.cookieName, this.config.cookieValue, { expires: 30, path: '/' })
    },

    cookieDelete: function () {
        let d = new Date();
        d.setTime(d.getTime() - (this.config.cookieTTLInSeconds));
        let expires = d.toGMTString();

        setCookie(this.config.cookieName, this.config.cookieValue, { expires: expires, path: '/' })
    },

    turnOn: function () {
        hp('html').on('mouseleave', function (e) {
            if (e.clientY <= popupDontLeave.config.maxDistanceFromTopToShowPopupInPx) {
                popupDontLeave.show();

                setTimeout(() => {
                    hp('.retargeting .close').removeClass('d-none')
                }, 3000)
            }
        })

        hp('.retargeting .close').on('click', () => {
            popupDontLeave.hide();
        })

        this.acceptBtn()
    },

    acceptBtn: function () {
        hp('.retargeting')
            .find('.button-primary, .button-modal')
            .on('click', () => {
                this.cookieSet()
            })
    },

    init: function () {
        if (this.isAllowedGeneral() && this.IsNotCartPage()) {
            this.turnOn();
        }
    },
}

export let popupDontLeave = new PopupDontLeave();
