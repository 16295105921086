'use strict';

class AdmitadLCW {
    urlParams;

    static admitadAffiliateId = 2052;
    static admitadAffiliateKey = 'WHMCSAffiliateID';
    static admitadUTMKey = "utm_source";
    static admitadUTMValue = "admitad";
    static cookiesPrefix = "admitad_lcw_";
    static cookiesLifeTime = 30; //Days

    static removeCookiesArr = [
        'WHMCSAffiliateID'
    ];

    constructor(params = window.location.search) {
        this.#setUrlParams(params);

        if (this.#isUrlContainsAdmitadParams()) {
            this.#removeOtherAffiliateCookies();
            this.#setAdmitadCookies();
            return;
        }

        if (this.#isAnotherSource()) {
            this.#removeAdmitadCookies();
        }
    }

    static init() {
        return new AdmitadLCW();
    }

    static cookies(key, value = undefined, days = AdmitadLCW.cookiesLifeTime) {
        return Cookies.cookies(key, value, days);
    }

    #isAnotherSource() {


        return (
            // Just another source
            (this.urlParams.utm_source != undefined && this.urlParams.utm_source != AdmitadLCW.admitadUTMValue)
            ||
            // Google source
            (
                this.urlParams.gclid != undefined || this.urlParams.gad_source != undefined
            )
        )
    }

    #removeAdmitadCookies() {
        AdmitadLCW.cookies('').forEach((cookie) => {
            let key = cookie[0];

            if (key.includes(AdmitadLCW.cookiesPrefix)) {
                Cookies.removeCookie(key)
            }
        })

        if (AdmitadLCW.cookies(AdmitadLCW.admitadAffiliateKey) == AdmitadLCW.admitadAffiliateId) {
            Cookies.removeCookie(AdmitadLCW.admitadAffiliateKey)
        }
    }

    #setAdmitadCookies() {
        Object.keys(this.urlParams).forEach((key) => {
            let value = this.urlParams[key];

            if (this.#isAdmitadCookie(key) || this.#isAdmitadCookie(undefined, value)) {
                AdmitadLCW.cookies(AdmitadLCW.cookiesPrefix + key, value, AdmitadLCW.cookiesLifeTime);
            }
        })

        AdmitadLCW.cookies(AdmitadLCW.admitadAffiliateKey, AdmitadLCW.admitadAffiliateId, AdmitadLCW.cookiesLifeTime);
    }

    #removeOtherAffiliateCookies() {
        AdmitadLCW.removeCookiesArr.forEach((key) => {
            Cookies.removeCookie(key)
        })
    }

    #isUrlContainsAdmitadParams() {
        return this.urlParams[AdmitadLCW.admitadUTMKey] != undefined
            && this.urlParams[AdmitadLCW.admitadUTMKey] == AdmitadLCW.admitadUTMValue
            && this.urlParams.admitad_uid != undefined;
    }

    #isAdmitadCookie(key = undefined, value = undefined) {
        if (value == undefined || ('' + value).length == 0) {
            return AdmitadLCW.admitadUTMKey == key || key == 'admitad_uid';
        }

        if (key == undefined || ('' + key).length == 0) {
            return AdmitadLCW.admitadUTMValue == value;
        }


        return AdmitadLCW.admitadUTMValue == value && (AdmitadLCW.admitadUTMKey == key || key == 'admitad_uid');
    }

    #setUrlParams(params = null) {
        this.urlParams = Object.fromEntries(new URLSearchParams(params));
    }
}

module.exports = AdmitadLCW;
