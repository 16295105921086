function CustomCollapser() {
    this.collapseBlocks = [];

    this.init();
}

CustomCollapser.prototype = {
    init: function () {
        if (!hp('[data-cc-target]').isEmpty()) {
            this._fillBlocks()
        }
    },

    _fillBlocks: function () {
        hp('[data-cc-target]').each((collapse, index) => {
            let block = new CustomCollapseElem(collapse)

            if (block.isValid()) {
                this.collapseBlocks.push(block)
            }
        })
    }
}

function CustomCollapseElem(elem) {
    this.collapseElemData = {
        'cc-target': '',
        'cc-collapser': '',
        'btn-block': '',
        'btn-type': ''
    }

    this.elem = hp(elem);

    this.setValues();
    this.setBTNs();
}

CustomCollapseElem.prototype = {
    setValues: function () {
        let needleAttributes = Object.keys(this.collapseElemData)

        const elem = this.elem;

        needleAttributes.forEach((key, index) => {
            let selector = elem.data(key)

            if (selector == null || selector == '') {
                return;
            }

            if (selector === 'self') {
                this.collapseElemData[key] = elem

                return;
            }

            if (key == 'btn-type') {
                this.collapseElemData[key] = selector

                if (selector == 'text') {
                    this.collapseElemData['btn-text'] = elem.data('btn-text')
                }

                return;
            }

            if (key == 'cc-target') {

                if (selector === 'self') {
                    this.collapseElemData[key] = elem

                    return;
                }

            }

            this.collapseElemData[key] = elem.find(selector)
        })
    },

    setBTNs: function () {
        const BTN_BLOCK = this.collapseElemData['btn-block'];
        const BTN_TYPE = this.collapseElemData['btn-type'];

        const OVERFLOW_COLLAPSER = this.collapseElemData['cc-collapser'];

        if (this.isCollapseNotNeed()) {
            OVERFLOW_COLLAPSER.removeClass('overflow')

            return;
        }

        const BTN = hp(this.getBtnByType(BTN_TYPE));

        BTN_BLOCK.append(BTN.elem.outerHTML)

        this.setBTNEvent(BTN_BLOCK.find('.more-btn'))
    },

    setBTNEvent: function (btn) {
        let collapser = this.collapseElemData['cc-collapser'];

        btn.on('click', (e) => {
            if (!btn.hasClass('active')) {
                collapser.removeClass('overflow')
                btn.addClass('active')

                return
            }

            collapser.addClass('overflow')
            btn.removeClass('active')
        })
    },

    getBtnByType: function (type) {

        let getArrowBTN = () => {
            let tempBTN = hp(document.createElement('div'))

            tempBTN.addClass('more-btn')

            return tempBTN
        }

        let getTextBTN = () => {
            let tempBTN = hp(document.createElement('div'))

            tempBTN.addClass('more-text-btn')

            let textSpan = hp(document.createElement('span'))

            textSpan.addClass('more-btn').text(this.collapseElemData['btn-text'])

            tempBTN.append(textSpan.elem.outerHTML)

            return tempBTN
        }

        const btnTypes = {
            arrow: getArrowBTN(),
            text: getTextBTN()
        };

        return btnTypes[type];
    },

    isCollapseNotNeed: function () {
        const OVERFLOW_TARGET = this.collapseElemData['cc-target'];
        const OVERFLOW_COLLAPSER = this.collapseElemData['cc-collapser'];

        OVERFLOW_COLLAPSER.addClass('overflow')

        const TARGET_CSS = getComputedStyle(OVERFLOW_TARGET.elem);

        const TARGET_LINE_HEIGHT = TARGET_CSS.getPropertyValue('line-height');
        const TARGET_MAX_LINES = TARGET_CSS.getPropertyValue('-webkit-line-clamp');

        OVERFLOW_COLLAPSER.removeClass('overflow')

        const TARGET_REAL_HEIGHT = OVERFLOW_TARGET.elem.offsetHeight

        OVERFLOW_COLLAPSER.addClass('overflow')


        return (parseInt(TARGET_MAX_LINES) * parseInt(TARGET_LINE_HEIGHT)) >= TARGET_REAL_HEIGHT
    },

    isValid: function () {
        return !(Object.values(this.collapseElemData).includes(''))
    }
}

new CustomCollapser
