if (document.querySelectorAll('.grab-scroll').length > 0) {
    hp('.grab-scroll').each((grabBlock, index) => {
        const slider = hp(grabBlock)

        let isDown = false;
        let startX;
        let scrollLeft;

        slider.on('mousedown', (e) => {
            isDown = true;
            slider.addClass('grabbing');
            startX = e.pageX - grabBlock.offsetLeft;
            scrollLeft = grabBlock.scrollLeft;
        })

        slider.on('mouseleave', (e) => {
            isDown = false;
            slider.removeClass('grabbing');
        })

        slider.on('mouseup', (e) => {
            isDown = false;
            slider.removeClass('grabbing');
        })

        slider.on('mousemove', (e) => {
            if (!isDown) {
                return;
            }
            e.preventDefault();

            const x = e.pageX - grabBlock.offsetLeft;
            const walk = (x - startX) * 1; //scroll-fast
            grabBlock.scrollLeft = scrollLeft - walk;
        })
    })
}
