//lang class
function Lang(baseURL) {
    let site_lang = hp('html').attr('lang') // get site lang segment
    let base_url = baseURL

    site_lang = (site_lang !== 'ru' && site_lang !== 'en' && site_lang !== 'uk') ? 'ru' : site_lang;

    let translations = {
        "ru": {
            "rub": "руб",
            "usd": "$",
            "uah": "грн",
            "eur": "€",
            "free": "Свободен",
            "available": "Доступен",
            "no-available": "Недоступен",
            "busy": "Занят",
            "order": "Заказать",
            "move": "Перенести",
            "move-msg": "Возможно это ваш домен",
            "monthly": "Месяц",
            "month": "мес",
            "quarterly": "Квартал",
            "semiannually": "Полгода",
            "annually": "Год",
            "biennially": "2 года",
            "triennially": "3 года",
            "five": "5 лет",
            "ten": "10 лет",
            "empty": "нет",
            "count": "шт",
            "gb": "GB",
            "core": "ядро",
            "cores": "ядра",
            "valid-old_user": "Данные введены неверно или пользователь не существует",
            "valid-data": "Данные не введены или неверно заполнены",
            "valid-email": "Email пуст или заполнен неверно",
            "valid-password": "Количество символов для пароля меньше 6 или не больше 255",
            "valid-password-old": "Количество символов для пароля меньше 3 или не больше 255",
            "valid-phone": "Номер телефона введен некорректно или добавте или удалите +",
            "valid-repeat-password": "Введённые пароли не совпадают",
            "valid-name": "Поле с именем должно быть заполнено и содержать не больше 255 символов",
            "valid-last-name": "Поле с фамилией должно быть заполнено и содержать не больше 255 символов",
            "valid-address": "Поле с адресом должно быть заполнено и содержать не больше 255 символов",
            "valid-company": "Поле с названием компании должно быть заполнено и содержать не больше 255 символов",
            "valid-city": "Поле с названием города должно быть заполнено и содержать не больше 255 символов",
            "valid-region": "Поле с названием области должно быть заполнено и содержать не больше 255 символов",
            "valid-index": "Поле с индексом должно быть заполнено и содержать только числа",
            "user-invalid": "Пользователь с такими данными не найден",
            "year-1": "год",
            "year-2": "года",
            "year-3": "года",
            "year-5": "лет",
            "year-10": "лет"
        },
        "en": {
            "rub": "RUB",
            "usd": "$",
            "uah": "₴",
            "eur": "€",
            "free": "Available",
            "available": "Accessible",
            "no-available": "Unavailable",
            "busy": "Taken",
            "order": "Order",
            "move": "Transfer",
            "move-msg": "Maybe it's your domain",
            "monthly": "Monthly",
            "month": "m",
            "quarterly": "3 months",
            "semiannually": "6 months",
            "annually": "Annually",
            "biennially": "2 years",
            "triennially": "3 years",
            "five": "5 years",
            "ten": "10 years",
            "empty": "no",
            "count": "pcs",
            "gb": "GB",
            "core": "core",
            "cores": "cores",
            "valid-old-user": "The entered data  is incorrect or user does not exist",
            "valid-data": "Data not entered or incorrectly filled",
            "valid-email": "Email is empty or is invalid",
            "valid-password": "The password is less than 6 symbols or more than 255",
            "valid-password-old": "The number password is less than 3 or more than 255",
            "valid-phone": "The phone number is incorrect add or delete +",
            "valid-repeat-password": "The passwords do not match",
            "valid-name": "The field with a name must be filled and contain no more than 255 characters",
            "valid-last-name": "A field with a surname must be filled and contain no more than 255 symbols",
            "valid-address": "The address must be filled and contain no more than 255 characters",
            "valid-company": "The name of the company must be filled and contain no more than 255 symbols",
            "valid-city": "The city must be filled and contain no more than 255 symbols",
            "valid-region": "The region must be filled and contain no more than 255 symbols",
            "valid-index": "The index must be filled and contain only numbers",
            "user-invalid": "User with this data not found",
            "year-1": "year",
            "year-2": "years",
            "year-3": "years",
            "year-5": "years",
            "year-10": "years"
        },
        "uk": {
            "rub": "руб",
            "usd": "$",
            "uah": "грн",
            "eur": "€",
            "free": "Вільний",
            "available": "Доступний",
            "no-available": "Недоступний",
            "busy": "Зайнятий",
            "order": "Замовити",
            "move": "Перенести",
            "move-msg": "Можливо це ваш домен",
            "monthly": "Місяц",
            "month": "міс",
            "quarterly": "Квартал",
            "semiannually": "Півроку",
            "annually": "Рік",
            "biennially": "2 роки",
            "triennially": "3 роки",
            "five": "5 років",
            "ten": "10 років",
            "empty": "ні",
            "count": "шт",
            "gb": "GB",
            "core": "ядро",
            "cores": "ядра",
            "valid-old-user": "Дані введені невірно або користувач не існує",
            "valid-data": "Дані не введені або невірно заповнені",
            "valid-email": "Email порожній або заповнений невірно",
            "valid-password": "Кількість символів для пароля менше 6 або більше 255",
            "valid-password-old": "Кількість символів для пароля менше 3 або більше 255",
            "valid-phone": " Номер телефону введен не коректно або додайте або видаліть +",
            "valid-repeat-password": "Введені паролі не збігаються",
            "valid-name": "Поле з іменем має бути заповнено і містити не більше 255 символів",
            "valid-last-name": "Поле з прізвищем  має бути заповнено і містити не більше 255 символів",
            "valid-address": "Поле з адресою  має бути заповнено і містити більше 255 символів",
            "valid-company": "Поле з назвою компанії має бути заповнено і містити не більше 255 символів",
            "valid-city": "Поле з назвою міста має бути заповнено і містити не більше 255 символів",
            "valid-region": "Поле з назвою області має бути заповнено і містити не більше 255 символів",
            "valid-index": "Поле з індексом повинно бути заповнене і містити тільки числа",
            "user-invalid": "Користувач з такими даними не знайдений",
            "year-1": "рік",
            "year-2": "роки",
            "year-3": "роки",
            "year-5": "років",
            "year-10": "років"
        }
    };

    setTitle(); //set selected language

    this.getBaseUrl = () => {
        return base_url
    }

    //public
    this.getSiteLang = function () {
        return site_lang;
    };

    this.getInterpreter = function () {
        return translations;
    };

    //get language json
    this.init = function () {
        let response = '';

        fetch(baseURL + '/storage/json/frontend.json')
            .then((response) => response.json())
            .then((data) => translations = data);

        return response;
    }

    //set selected lang in .lang-block > .selected
    function setTitle() {
        hp('#lang-dropdown .selected').text(
            hp('.lang-block').find('li.active a').text()
        )
    }
}

module.exports = Lang;
