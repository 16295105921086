'use strict'

class CSRF {
    csrf = '';

    constructor() {
        this.csrf = this.parseCSRF()
    }

    parseCSRF(){
        return document.querySelector('meta[name="csrf-token"]').getAttribute('content')
    }

    static onloadEvent() {
        if (!isStaticPage()) {
            return
        }

        let csrf = CSRF.getNewCSRF()

        CSRF.setCSRF(csrf)
        document.querySelector('meta[name="csrf-token"]').setAttribute('content', csrf)

        document.querySelectorAll('input[name="_token"][type="hidden"]').forEach((elem) => {
            elem.setAttribute('value', csrf)
        })
    }

    static getNewCSRF() {
        let newCSRF = '';
        let request = new XMLHttpRequest()

        request.onload = () => {
            let res = JSON.parse(request.response)
            newCSRF = res.csrf_token ?? '';
        }

        request.onerror = () => {
            let res = JSON.parse(request.response)
            console.error(res);
        }

        request.open('GET', '/csrf-token', false)
        request.send()


        return newCSRF;
    }

    static setCSRF(csrf) {
        CSRF.getOrCreateInstance().csrf = csrf
    }

    static getOrCreateInstance() {
        if (!window.CSRF || !(window.CSRF instanceof CSRF)) {
            window.CSRF = new CSRF()
        }

        return window.CSRF
    }
}

module.exports = CSRF
