/**
 * This function find in cookies needle cookie by it name
 * Or if didn`t find return false
 *
 * @param {string} name
 */
export let getCookie = (name = '') => {
    if (name == '') {
        return document.cookie;
    }

    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) {
        return parts.pop().split(';').shift()
    };

    return null;
}

/**
 * This function needs to set cookie
 *
 * @example setCookie('key', 'val', { expires: 3, path: '/' })
 *
 * @param {string} key
 * @param {*} value
 * @param {object} options object with params `expires` (in days) and `path`
 */
export let setCookie = (key, value, options) => {
    if (typeof options.expires == 'undefined' || typeof options.path == 'undefined') {
        return false
    }

    const d = new Date();
    d.setTime(d.getTime() + (options.expires * 24 * 60 * 60 * 1000));
    let expires = "expires=" + d.toUTCString();

    document.cookie = key + "=" + value + ";" + expires + ";path=" + options.path;

    return true
}

/**
 *
 * @param {*} elem
 * @returns {Helpers}
 */
export let helpers = (elem = 'body') => new Helpers(elem);


function Helpers(elem = 'body') {

    /**
     * Element or array of elements that call for help)
     *
     * @type {HTMLElement|Array}
     */
    this.elem;

    /**
     * Temp value for element display status.
     *
     * @type {string}
     */
    this._elemOldDisplay = 'block';

    /**
    * List of available events
    *
    * @type {object}
    */
    this._eventList = {
        // values events
        change: 'change',
        onchange: 'change',
        submit: 'submit',
        onsubmit: 'submit',
        toggle: 'toggle',
        ontoggle: 'toggle',

        // focus events
        blur: 'blur',
        onblur: 'blur',
        focus: 'focus',
        onfocus: 'focus',
        focusin: 'focusin',
        onfocusin: 'focusin',
        focusout: 'focusout',
        onfocusout: 'focusout',

        // keyboard events
        keydown: 'keydown',
        onkeydown: 'keydown',
        keypress: 'keypress',
        onkeypress: 'keypress',
        keyup: 'keyup',
        onkeyup: 'keyup',

        // mouse events
        click: 'click',
        onclick: 'click',
        dblclick: 'dblclick',
        ondblclick: 'dblclick',
        mousedown: 'mousedown',
        onmousedown: 'mousedown',
        mouseup: 'mouseup',
        onmouseup: 'mouseup',
        mouseenter: 'mouseenter',
        onmouseenter: 'mouseenter',
        mouseleave: 'mouseleave',
        onmouseleave: 'mouseleave',
        mousemove: 'mousemove',
        onmousemove: 'mousemove',
        mouseout: 'mouseout',
        onmouseout: 'mouseout',
        mouseover: 'mouseover',
        onmouseover: 'mouseover',
        contextmenu: 'contextmenu',
        oncontextmenu: 'contextmenu',
        select: 'select',
        onselect: 'select',
        scroll: 'scroll',
        onscroll: 'scroll',

        // Page events
        error: 'error',
        onerror: 'error',
        load: 'load',
        onload: 'load',
        ready: 'DOMContentLoaded',
        resize: 'resize',
    };

    this._init(elem);
}

Helpers.prototype = {
    //---------------------------------------------------

    /**
     * Init section start
     */

    /**
    * Constructor of the `Helpers` class.
    *
    * @param {HTMLElement|string|Helpers} elem - The element or query string for your element, or an instance of `Helpers`.
    */
    _init: function (element) {
        this.setElem(element);

        if (!this._isElementIterable()) {
            try {
                if (this.elem.style.display != '') {
                    this._elemOldDisplay = this.elem.style.display
                }
            } catch (e) {
                this._elemOldDisplay = 'block'
            }
        }
    },

    /**
    * Sets the element to work with.
    *
    * @param {HTMLElement|string|Helpers} elem - The `HTMLElement` or query string for your element, or an instance of `Helpers`.
    * @returns {Helpers} The current instance of `Helpers`.
    */
    setElem: function (elem) {
        if (typeof elem == 'string') {
            this.elem = this._find(elem);

            if (this.elem !== undefined) {
                return this;
            }

            this.elem = null;

            // TODO: remove on production
            // console.log('Failed to find element by query ' + elem + ', check your query!');

            return this;
        }

        if (elem instanceof Helpers) {
            this._createHelperFromHelper(elem);


            return this;
        }

        this.elem = this._checkElem(elem);


        return this;
    },

    /**
     * Init section end
     */

    //---------------------------------------------------

    /**
     * Other functions start
     */

    /**
     * Executes a provided function once for each element(s).
     *
     * @param {function} callback - A function to execute for each matched element.
     * @returns {Helpers} The current instance of `Helpers`.
     */
    each: function (callback) {
        if (this._isElementIterable()) {
            this._iterateElem((elem, index) => {
                callback(elem, index);
            });


            return this;
        }

        callback(this.elem, 0);


        return this;
    },

    /**
     * Splits the elements into chunks of a specified size and executes a callback for each chunk.
     *
     * @param {number} chunkSize - The size of each chunk. Defaults to 10.
     * @param {function} callback - A function to execute for each chunk.
     * @return {Helpers} The current instance of `Helpers`.
     */
    chunk: function (chunkSize = 10, callback) {
        if (this._isElementIterable) {
            for (let i = 0; i < this.elem.length; i += chunkSize) {
                callback(this.elem.slice(i, i + chunkSize), i);
            }

            return this;
        }

        callback([this.elem], 0);


        return this;
    },

    last: function () {
        if (this._isElementIterable()) {
            return helpers(this.elem.slice(-1)[0])
        }


        return this
    },

    first: function () {
        if (this._isElementIterable()) {
            return helpers(this.elem.shift())
        }


        return this
    },

    isEmpty: function () {
        if (this._isElementIterable()) {
            return this.elem.length == 0;
        }


        return this.elem == undefined || this.elem == null;
    },

    /**
     * Creates a array of objects containing key/value pairs from form fields in the selected element(s).
     *
     * @returns {object[]} An array of objects containing name/value pairs for each form field in the selected element(s).
     */
    serializeArray: function () {
        let serialized = [];

        if (this._isElementIterable()) {
            this._iterateElem((elem, index) => {
                let formData = new FormData(elem);

                for (let pair of formData.entries()) {
                    serialized[index].push({
                        'name': pair[0],
                        'value': pair[1]
                    });
                }
            })


            return serialized;
        }

        let formData = new FormData(this.elem);

        for (let pair of formData.entries()) {
            serialized.push({
                'name': pair[0],
                'value': pair[1]
            });
        }


        return serialized;
    },

    /**
     * Other functions end
     */

    //---------------------------------------------------

    /**
     * Work with element text, html, dataset ... start
     */

    /**
        * Get or set the text content of the element(s).
        *
        * @param {string} [text] The text content to set. If not provided, returns the current text content.
        * @returns {string|Helpers} The current text content or the current instance of `Helpers`.
        */
    text: function (text = undefined) {
        if (text == undefined) {
            return this._getTextContent();
        }

        return this._setTextContent(text);
    },

    /**
     * Set the text content of the element(s).
     *
     * @param {string} text - The text content to set.
     * @returns {Helpers} The current instance of `Helpers`.
     */
    _setTextContent: function (text) {
        if (this._isElementIterable()) {

            this._iterateElem((elem, index) => {
                elem.textContent = text;
            });


            return this
        }

        this.elem.textContent = text;


        return this;
    },

    /**
     * Get the text content of the element(s).
     *
     * @returns {Array|string} An array of text content strings or a single text content string.
     */
    _getTextContent: function () {
        if (this._isElementIterable()) {
            let temp = [];

            this._iterateElem((elem, index) => {
                temp.push(elem.textContent)
            })


            return temp
        }


        return this.elem.textContent
    },

    /**
     * Gets or sets the inner HTML content of the current element(s).
     *
     * @param {string} [html] - The HTML content to set.
     *
     * @returns {Helpers|string[]}
     * If `html` is not provided, returns the inner HTML content of element(s) in the collection.
     * Otherwise, returns the current instance of `Helpers`.
     */
    html: function (html = undefined) {
        if (html == undefined) {
            return this._getHTMLContent();
        }

        return this._setHTMLContent(html);
    },

    /**
     * Sets the inner HTML content of the current element(s).
     *
     * @param {string} html - The HTML content to set.
     *
     * @returns {Helpers} The current instance of `Helpers`.
     */
    _setHTMLContent: function (html) {
        if (this._isElementIterable()) {
            this._iterateElem((elem, index) => {
                elem.innerHTML = html
            })


            return this
        }

        this.elem.innerHTML = html


        return this
    },

    /**
     * Gets the inner HTML content of the current element(s).
     *
     * @returns {string[]} An array containing the inner HTML content of each element in the collection.
     */
    _getHTMLContent: function () {
        if (this._isElementIterable()) {
            let temp = [];

            this._iterateElem((elem, index) => {
                temp.push(elem.innerHTML)
            })


            return temp
        }

        return this.elem.innerHTML
    },

    /**
    * Get or set the data attribute of the element(s).
    *
    * @param {string} [key] - The name of the data attribute to get or set.
    * @param {string|number} [value] - The value to set for the specified data attribute.
    * @returns {string[]|string|null|object|Helpers}
    * - If `key` is provided, it returns the value of the specified data attribute for the element(s) in the collection or null if the attribute doesn't exist.
    * - If `key` is not provided, it returns an object with all the data attributes for the element(s) in the collection or an empty object if there are no data attributes.
    * - If both `key` and `value` are provided, it sets the value of the data attribute for all the elements in the collection and returns the current instance of `Helpers`.
    */
    data: function (key = undefined, value = undefined) {
        if (value == undefined) {
            return this._getDataAttribute(key);
        }

        return this._setDataAttribute(key, value);
    },

    /**
     * Get the data attribute of the element(s)
     *
     * @param {string} [key] - The name of the data attribute to get.
     * @returns {string[]|string|null|object}
     * - If `key` is provided, it returns the value of the specified data attribute for the element(s) in the collection or null if the attribute doesn't exist.
     * - If `key` is not provided, it returns an object with all the data attributes for the element(s) in the collection or an empty object if there are no data attributes.
     */
    _getDataAttribute: function (key = undefined) {
        if (key != undefined) {
            if (this._isElementIterable()) {
                let temp = [];

                this._iterateElem((elem, index) => {
                    if (elem.hasAttribute('data-' + key)) {
                        temp.push(elem.getAttribute('data-' + key));
                    }
                })

                return temp
            }

            if (this.elem.hasAttribute('data-' + key)) {
                return this.elem.getAttribute('data-' + key)
            }

            return null;
        }

        if (this._isElementIterable()) {
            let temp = [];

            this._iterateElem((elem, index) => {
                if (JSON.stringify(elem.dataset) != '{}') {
                    temp.push(JSON.parse(JSON.stringify(elem.dataset)))
                }
            })


            return temp
        }


        return JSON.parse(JSON.stringify(this.elem.dataset))
    },

    /**
     * Set the data attribute of the element(s)
     *
     * @param {string} key - The name of the data attribute to set.
     * @param {string|number} value - The value to set for the specified data attribute.
     * @returns {Helpers} The current instance of `Helpers`.
     */
    _setDataAttribute: function (key, value) {
        if (this._isElementIterable()) {
            this._iterateElem((elem, index) => {
                elem.dataset[key] = value
            })

            return this;
        }

        this.elem.dataset[key] = value;


        return this;
    },

    /**
     * Get or set the attribute of the element(s).
     *
     * @param {string} key - The name of the attribute to get or set.
     * @param {string|number} [value] - The value to set for the specified attribute.
     * @returns {string|string[]|null|Helpers}
     * - If `value` is provided, it sets the value of the `key` attribute for the element(s) in the collection and returns the current instance of `Helpers`.
     * - If `value` is not provided, it return the value of the attribute for element(s) in the collection.
     */
    attr: function (key, value = undefined) {
        if (value == undefined) {
            return this._getAttribute(key);
        }

        return this._setAttribute(key, value);
    },

    removeAttr: function (key) {
        if (this._isElementIterable()) {
            this._iterateElem((elem, index) => {
                elem.removeAttribute(key)
            })

            return this;
        }

        this.elem.removeAttribute(key)

        return this
    },

    /**
     * Get the attribute of the element(s).
     *
     * @param {string} key - The name of the attribute to get.
     * @returns {string|string[]|null}
     * It return the value of the attribute for element(s) in the collection.
     */
    _getAttribute: function (key) {
        if (this._isElementIterable()) {
            let temp = [];

            this._iterateElem((elem, index) => {
                if (elem.hasAttribute(key)) {
                    temp.push(elem.getAttribute(key))
                }
            })

            return temp
        }

        if (this.elem.hasAttribute(key)) {
            return this.elem.getAttribute(key)
        }

        return null;
    },

    /**
     * Set the attribute of the element(s).
     *
     * @param {string} key - The name of the attribute to set.
     * @param {string|number} value - The name of the attribute to set.
     * @returns {Helpers} The current instance of `Helpers`.
     */
    _setAttribute: function (key, value) {
        if (this._isElementIterable()) {
            this._iterateElem((elem, index) => {
                elem.setAttribute(key, value)
            })


            return this;
        }

        this.elem.setAttribute(key, value)

        return this;
    },

    /**
     * Get or set the attribute of the element(s), that needs value of `boolean` type.
     *
     * @param {string} key - The name of the attribute to get or set.
     * @param {boolean} [value] - The value to set for the specified attribute.
     * @returns {string|string[]|number|null|Helpers}
     * - If `value` is provided, it sets the value of the `key` attribute for the element(s) in the collection and returns the current instance of `Helpers`.
     * - If `value` is not provided, it return the value of the attribute for element(s) in the collection.
     */
    prop: function (key, value = undefined) {
        if (value == undefined) {
            return this._getAttributeForProp(key);
        }

        if (!this._isBoolean(value)) {
            value = false
        }

        return this._setAttributeForProp(key, value);
    },

    /**
     * Get the attribute of the element(s).
     *
     * @param {string} key - The name of the attribute to get.
     * @returns {string|string[]|null} return the value of the attribute for element(s) in the collection.
     */
    _getAttributeForProp: function (key) {
        if (this._isElementIterable()) {
            let temp = [];

            this._iterateElem((elem, index) => {
                let tempValue = false

                if (elem.hasAttribute(key)) {
                    tempValue = elem.getAttribute(key)
                }

                temp.push(tempValue);
            })

            if (temp.length >= 1) {
                return temp;
            }

            return undefined;
        }

        if (this.elem.hasAttribute(key)) {
            return this.elem.getAttribute(key);
        }


        return false;
    },

    /**
     * Set the attribute of the element(s).
     *
     * @param {string} key - The name of the attribute to set.
     * @param {boolean} value - The name of the attribute to set.
     * @returns {Helpers} The current instance of `Helpers`.
     */
    _setAttributeForProp: function (key, value) {
        if (this._isElementIterable()) {
            this._iterateElem((elem, index) => {
                elem.setAttribute(key, value)
            })


            return this;
        }

        this.elem.setAttribute(key, value);


        return this;
    },

    /**
     * Get or set the current value of the element(s).
     *
     * @param {*} [value] - The value that need to set.
     * @returns {*|Helpers}
     * - If `value` is provided, it sets the value for the element(s) in the collection and returns the current instance of `Helpers`.
     * - If `value` is not provided, it return the value of the element(s) in the collection.
     */
    val: function (value = undefined) {
        if (value != undefined) {
            return this._setValueForElem(value);
        }


        return this._getValueForElem();
    },

    /**
    * Set the current value of the element(s).
    *
    * @param {*} value - The value that need to set.
    * @returns {Helpers} The current instance of `Helpers`.
    */
    _setValueForElem: function (value) {
        if (this._isElementIterable()) {

            this._iterateElem((elem, index) => {
                elem.value = value;
            });

            return this;
        }

        this.elem.value = value;


        return this;
    },

    /**
    * Get the current value of the element(s).
    *
    * @returns {string|string[]|null|number}
    */
    _getValueForElem: function () {
        if (this._isElementIterable()) {
            let temp = [];

            this._iterateElem((elem, index) => {
                let tempValue = null;

                if (elem.value !== undefined) {
                    tempValue = elem.value;
                }

                temp.push(tempValue);
            });


            return temp;
        }

        if (this.isEmpty(this.elem)) {
            return null;
        }


        return this.elem.value;
    },

    /**
     * Work with element text, html, dataset ... end
     */

    //---------------------------------------------------

    /**
     * Events start
     */

    /**
    * Delegate an event listener to a selector.
    *
    * @param {string} selector - The selector to which the `event` listener should be delegated.
    * @param {string} eventType - The type of the event to listen for.
    * @param {function} callback - The callback function to execute when the `event` is triggered.
    * @returns {Helpers} The current instance of `Helpers`.
    */
    delegate: function (selector, eventType, callback) {
        let tempCallback = (e) => {
            let target = e.target;

            while (target && !target.matches(selector) && target !== this.elem) {
                target = target.parentNode;
            }

            if (target && target !== this.elem) {
                callback.call(target, e);
            }
        }

        if (this._isElementIterable()) {
            this._iterateElem((elem, index) => {
                helpers(elem).on(eventType, tempCallback)
            })


            return this;
        }

        helpers(this.elem).on(eventType, tempCallback);


        return this;
    },

    /**
     * Add an event listener to an element(s).
     *
     * @param {string} eventType - The type of the event to listen for.
     * @param {function} callback - The callback function to execute when the `event` is triggered.
     * @returns {Helpers} The current instance of `Helpers`.
     */
    on: function (eventType, callback) {
        eventType = this._convertEventType(eventType);

        if (this._isElementIterable()) {
            this._iterateElem((elem, index) => {
                this._createEventForElem(elem, eventType, (e) => callback(e));
            });


            return this;
        }

        this._createEventForElem(this.elem, eventType, (e) => callback(e));


        return this;
    },

    /**
     * Remove an event listener from an element or a list of elements.
     *
     * @param {string} eventType - The type of the event to remove.
     * @returns {Helpers} The current instance of `Helpers`.
     */
    off: function (eventType) {
        eventType = this._convertEventType(eventType)

        if (this._isElementIterable()) {
            this._iterateElem((elem, index) => {
                if (this._isElementHaveEvent(elem, eventType)) {
                    elem[eventType] = undefined;
                }
            });


            return this;
        }

        if (this._isElementHaveEvent(this.elem, eventType)) {
            this.elem[eventType] = undefined;
        }


        return this;
    },

    /**
     * Attach mouseenter and mouseleave event listeners to an element or a list of elements(s)
     *
     * @param {function} callbackIn - The callback function to execute when the mouse enters the element(s)
     * @param {function} callbackOut - The callback function to execute when the mouse leaves the element(s)
     * @returns {Helpers} The current instance of `Helpers`.
     */
    hover: function (callbackIn, callbackOut) {
        if (this._isElementIterable()) {
            this._iterateElem((elem, index) => {
                helpers(elem)
                    .on('mouseenter', callbackIn)
                    .on('mouseleave', callbackOut);
            });


            return this;
        }

        helpers(this.elem)
            .on('mouseenter', callbackIn)
            .on('mouseleave', callbackOut);


        return this;
    },

    /**
     * Trigger an event on an element or a list of element(s)
     *
     * @param {string} eventType - The type of the event to trigger
     * @returns {Helpers} The current instance of `Helpers`.
     */
    trigger: function (eventType) {
        let event;
        eventType = this._convertEventType(eventType);

        if (typeof (Event) === 'function') {
            // For modern browsers that support the Event constructor
            event = new Event(eventType);
        } else {
            // For older browsers that do not support the Event constructor
            event = document.createEvent('Event');
            event.initEvent(eventType);
        }

        if (this._isElementIterable()) {
            this._iterateElem((elem, index) => {
                elem[eventType]()

                elem.dispatchEvent(event);
            });


            return this;
        }

        this.elem[eventType]()

        this.elem.dispatchEvent(event);


        return this;
    },

    /**
     * Add an event listener to an element(s) that will only execute once
     *
     * @param {string} eventType - The type of the event to listen for
     * @param {function} callback - The callback function to execute when the `event` is triggered
     * @returns {Helpers} The current instance of `Helpers`.
     */
    one: function (eventType, callback) {
        eventType = this._convertEventType(eventType)

        if (this._isElementIterable()) {
            this._iterateElem((elem, index) => {
                let tempCallback = (e) => {
                    callback(e);
                    elem.removeEventListener(eventType, tempCallback);
                }

                elem.addEventListener(eventType, tempCallback);
            });


            return this;
        }

        let tempCallback = (e) => {
            callback(e);
            this.elem.removeEventListener(eventType, tempCallback);
        }

        this.elem.addEventListener(eventType, tempCallback);

        return this;
    },

    /**
     * Adds an event listener to the given target element with the specified event type and callback.
     *
     * @param {HTMLElement} target - The element to add the event listener to.
     * @param {string} eventType - The type of event to listen for.
     * @param {function} callback - The function to be executed when the event is triggered.
     * @returns {Helpers} The current instance of `Helpers`.
     *
     */
    _createEventForElem: function (target, eventType, callback) {
        if (this.isEmpty()) {
            return;
        }

        target.addEventListener(eventType, e => {
            callback(e)
        });


        return this;
    },

    /**
     * Converts the given event type to its standard name, if available in the `eventList` property of the `Helpers` class.
     * Throws an error if the event type is not recognized.
     *
     * @param {string} event - The event type to convert.
     * @returns {string} - The standardized event type.
     * @throws {Error} If the given event type is not recognized.
     */
    _convertEventType: function (event) {
        if (this._eventList[event] != '' || this._eventList[event] != undefined) {
            return this._eventList[event];
        }

        throw new Error('Wrong event: ' + event);
    },

    /**
     * Events end
     */

    //---------------------------------------------------

    /**
     * Work with DOM start
     */

    /**
     * Hides the element(s) by setting their `display` style property to 'none'.
     *
     * @returns {Helpers} The current instance of `Helpers`.
     */
    hide: function () {
        if (this._isElementIterable()) {
            this._iterateElem((elem, index) => {
                elem.style.display = 'none';
            });


            return this;
        }

        this.elemOldDisplay = this.elem.style.display;

        this.elem.style.display = 'none';


        return this;
    },

    /**
     * Shows the element(s) by setting their `display` style property to the value it had before calling the `hide()` method, or `block` if not.
     *
     * @returns {Helpers} The current instance of `Helpers`.
     */
    show: function () {
        let newDisplay = (this.elemOldDisplay == 'none' || this.elemOldDisplay == undefined) ? 'block' : this.elemOldDisplay

        if (this._isElementIterable()) {
            this._iterateElem((elem, index) => {
                elem.style.display = newDisplay;
                elem.style.opacity = 1;
            });


            return this;
        }

        this.elem.style.display = newDisplay;
        this.elem.style.opacity = 1;


        return this;
    },

    is: function (selector) {
        if (this._isElementIterable()) {
            this._iterateElem((elem, index) => {
                if (!elem.matches(selector)) {
                    return false
                }
            })

            return true;
        }

        if (this.isEmpty(this.elem)) {
            return false
        }

        return this.elem.matches(selector);
    },

    count: function () {
        if (this._isElementIterable()) {
            return this.elem.length
        }

        return 1
    },

    /**
    * Finds elements that match the provided selector within the current set of elements or their children.
    *
    * @param {string} needle - A string containing a selector expression to match elements against.
    * @returns {Helpers} The current instance of `Helpers`.
    */
    find: function (needle) {
        if (this._isElementIterable()) {
            let temp = [];

            this._iterateElem((elem, index) => {
                if (elem.querySelectorAll(needle).length >= 1) {
                    temp = this._toArray(elem.querySelectorAll(needle));
                }
            });

            if (temp.length >= 1) {
                return helpers(temp)
            }


            return this;
        }

        if (this.isEmpty(this.elem)) {
            return this;
        }

        if (this.elem.querySelectorAll(needle).length == 1) {
            return helpers(
                this._toArray(
                    this.elem.querySelectorAll(needle)
                ).slice(-1)[0]
            )
        }


        return helpers(
            this._toArray(
                this.elem.querySelectorAll(needle)
            )
        )
    },

    children: function (selector) {
        const childrenArray = [];

        if (this._isElementIterable()) {
            this._iterateElem((elem, index) => {
                let childElements = this._toArray(elem.children);

                childElements.forEach((childElement, i) => {
                    if (childrenArray[index] == undefined) {
                        childrenArray[index] = [];
                    }



                    if (selector) {
                        if (childElement.matches(selector)) {
                            if (childrenArray[index][i] == undefined) {
                                childrenArray[index][i] = [];
                            }

                            childrenArray[index][i].push(childElement)
                        }
                    } else {
                        if (childrenArray[index][i] == undefined) {
                            childrenArray[index][i] = [];
                        }

                        childrenArray[index][i].push(childElement)
                    }
                })
            })


            return helpers(childrenArray);
        }

        let childElements = this._toArray(this.elem.children);

        childElements.forEach((childElement) => {
            if (selector) {
                if (childElement.matches(selector)) {
                    childrenArray.push(childElement)
                }
            } else {
                childrenArray.push(childElement)
            }
        })


        return helpers(childrenArray);
    },

    /**
     * Sets the element to the next sibling element.
     *
     * @returns {Helpers} The current instance of `Helpers`.
     */
    next: function () {
        if (this._isElementIterable()) {
            let temp = [];

            this._iterateElem((elem, index) => {
                temp.push(elem.nextElementSibling)
            });

            if (temp.length >= 1) {
                return helpers(temp);
            }


            return this;
        }



        return helpers(this.elem.nextElementSibling);
    },

    /**
     * Sets the element to the previous sibling element.
     *
     * @returns {Helpers} The current instance of `Helpers`.
     */
    prev: function () {
        if (this._isElementIterable()) {
            return this.last();
        }


        return helpers(this.elem.previousElementSibling);
    },

    /**
     * Sets the element to the element at the specified index in the collection.
     *
     * @param {number} index - The zero-based index of the element to set the current element to.
     * @returns {Helpers} The current instance of `Helpers`.
     */
    eq: function (index) {
        if (index == undefined) {
            return this;
        }

        index = Math.abs(index);

        if (this._isElementIterable()) {
            if (this.elem.length - 1 >= index) {
                return helpers(this.elem[index])
            }

            return this
        }


        return this
    },

    index: function () {
        if (this._isElementIterable()) {
            let siblings = Array.from(helpers(this.elem).first().elem.parentNode.children);

            return siblings.indexOf(helpers(this.elem).first().elem);
        }

        let siblings = Array.from(this.elem.parentNode.children);


        return siblings.indexOf(this.elem);
    },

    /**
     * Gets the parent of element(s) in the current set of elements, optionally filtered by a selector.
     *
     * @param {string} [selector] - A string containing a selector expression to match elements against.
     * @returns {Helpers} The current instance of `Helpers`.
     */
    parent: function (selector = undefined) {
        if (selector != undefined) {
            selector = selector.replace('.', '');

            if (this._isElementIterable()) {
                let temp = [];

                this._iterateElem((elem, index) => {
                    if (elem.parentNode.classList.contains(selector)) {
                        temp.push(elem.parentNode);
                    }
                })

                if (temp.length >= 1) {
                    return helpers([...new Set(temp)]);
                }


                return this;
            }

            if (this.elem.parentNode.classList.contains(selector)) {
                return helpers(this.elem.parentNode)
            }


            return this;
        }

        if (this._isElementIterable()) {
            let temp = []

            this._iterateElem((elem, index) => {
                temp.push(elem.parentNode)
            })

            if (temp.length >= 1) {
                return helpers([...new Set(temp)])
            }

            return this;
        }

        if (this.elem.parentNode && this.elem.parentNode.nodeType !== 11) {
            return helpers(this.elem.parentNode);
        }


        return this;
    },

    /**
     * Gets the parents of element(s) in the current set of elements, optionally filtered by a selector.
     *
     * @param {string} [selector] - A string containing a selector expression to match elements against.
     * @returns {Helpers} The current instance of `Helpers`.
     */
    parents: function (selector = undefined) {
        if (selector != undefined) {
            selector = selector.replace('.', '')
            if (this._isElementIterable()) {
                let temp = [];

                this._iterateElem((elem, index) => {
                    let tempArray = this._dir(elem, 'parentNode')

                    for (let element in tempArray) {
                        element = tempArray[element];

                        if (element.classList.contains(selector)) {
                            temp.push(tempArray);

                            break;
                        }
                    }

                })

                if (temp.length >= 1) {
                    const unique = new Map();

                    temp.forEach((subArr) => {
                        const key = subArr.toString();

                        if (!unique.has(key)) {
                            unique.set(key, subArr);
                        }
                    });

                    return helpers(this._toArray(unique.values()))
                }

                return this;
            }


            let temp = this._dir(this.elem, 'parentNode');

            temp.forEach(element => {
                if (element.classList.contains(selector)) {
                    return helpers(temp);
                }
            })


            return this;
        }

        if (this._isElementIterable()) {
            let temp = [];

            this._iterateElem((elem, index) => {
                temp.push(this._dir(elem, 'parentNode'));
            })

            if (temp.length >= 1) {
                const unique = new Map();

                temp.forEach((subArr) => {
                    const key = subArr.toString();

                    if (!unique.has(key)) {
                        unique.set(key, subArr);
                    }

                });

                return helpers(this._toArray(unique.values()))
            }

            return this;
        }


        return helpers(this._dir(this.elem, 'parentNode'));
    },

    /**
     * Returns all ancestor elements of the current selection, up to but not including
     * the element matched by the specified selector.
     *
     * @param {string} selector - The selector to stop searching at.
     * @returns {Helpers} The current instance of `Helpers`.
     */
    parentsUntil: function (selector) {
        if (selector == '' || selector == undefined) {
            return this;
        }

        selector = selector.replace('.', '');

        if (this._isElementIterable()) {
            let temp = [];

            this._iterateElem((elem, index) => {
                temp.push(this._dir(elem, 'parentNode', selector))
            })

            if (temp.length >= 1) {
                const unique = new Map();

                temp.forEach((subArr) => {
                    const key = subArr.toString();

                    if (!unique.has(key)) {
                        unique.set(key, subArr);
                    }

                });

                return helpers(this._toArray(unique.values())[0]);
            }


            return this;
        }

        if (this._dir(this.elem, 'parentNode', selector).length >= 1) {
            return helpers(this._dir(this.elem, 'parentNode', selector))
        }

        return this;
    },

    /**
     * Returns an array of elements, starting from the current element and going up the DOM tree
     * until a specified parent element is reached. If `until` is not specified, all ancestor elements will be included.
     *
     * @param {HTMLElement} elem - The starting element.
     * @param {string} [dir='parentNode'] - The direction to traverse the DOM tree in.
     * @param {string} [until] - The selector of the parent element to stop at.
     * @returns {Array} - An array of elements starting from the current element and going up the DOM tree.
    */
    _dir: function (elem, dir = 'parentNode', until) {
        let matched = [];
        let truncate = until !== undefined;

        matched.push(elem)

        while ((elem = elem[dir]) && elem.nodeType !== 9) {
            if (elem.nodeType === 1) {
                if (
                    truncate
                    &&
                    (
                        elem.nodeName.toLowerCase() == until.toLowerCase()
                        ||
                        elem.classList.contains(until)
                    )
                ) {
                    matched.push(elem);
                    break;
                }
                matched.push(elem);
            }
        }

        return matched;
    },

    before: function (content) {
        if (this._isElementIterable()) {
            this._iterateElem((elem, index) => {
                elem.insertAdjacentHTML('beforebegin', content);
            })


            return this;
        }

        this.elem.insertAdjacentHTML('beforebegin', content);


        return this;
    },

    append: function (content) {
        if (this._isElementIterable()) {
            this._iterateElem((elem, index) => {
                elem.insertAdjacentHTML('beforeend', content);
            })


            return this;
        }

        this.elem.insertAdjacentHTML('beforeend', content);


        return this;
    },

    prepend: function (content) {
        if (this._isElementIterable()) {
            this._iterateElem((elem, index) => {
                elem.insertAdjacentHTML('afterbegin', content);
            })


            return this;
        }

        this.elem.insertAdjacentHTML('afterbegin', content);


        return this;
    },

    /**
     * Work with DOM end
     */

    //---------------------------------------------------

    /**
     * Work with element class list and style start
     */

    /**
     * Adds the specified `className` to element(s).
     *
     * @param {string} className - The class name that need to add to element(s).
     * @returns {Helpers} The current instance of `Helpers`.
     */
    addClass: function (classNames) {
        let classes = classNames.split(' ');

        this._iterate(classes, (className, index) => {
            if (this._isElementIterable()) {
                this._iterateElem((elem, index) => {
                    elem.classList.add(className);
                })


                return this;
            }

            this.elem.classList.add(className);

            return this
        })


        return this
    },

    /**
     * Remove the specified `className` from element(s).
     *
     * @param {string} className - The class name that need to remove.
     * @returns {Helpers} The current instance of `Helpers`.
     */
    removeClass: function (classNames) {
        let classes = classNames.split(' ');

        this._iterate(classes, (className, index) => {
            if (this._isElementIterable()) {
                this._iterateElem((elem, index) => {
                    elem.classList.remove(className);
                })


                return this;
            }

            this.elem.classList.remove(className);

            return this
        })


        return this
    },

    hasClass: function (className) {
        if (this._isElementIterable()) {
            this._iterateElem((elem, index) => {
                if (elem.classList.contains(className)) {
                    return elem.classList.contains(className);
                }
            });


            return false;
        }


        return this.elem.classList.contains(className);
    },

    /**
     * Add or remove the specified `className` from or to element(s).
     *
     * @param {string} className - The class name that need to remove or add.
     * @returns {Helpers} The current instance of `Helpers`.
     */
    toggle: function (className) {
        if (this._isElementIterable()) {
            this._iterateElem((elem, index) => {
                if (elem.classList) {
                    elem.classList.toggle(className);
                } else {
                    let classes = elem.className.split(' ');
                    let existingIndex = -1;

                    classes.forEach((value, index) => {
                        if (value === className) {
                            existingIndex = index;
                        }
                    });

                    if (existingIndex >= 0) {
                        classes.splice(existingIndex, 1);
                    } else {
                        classes.push(className);
                    }

                    elem.className = classes.join(' ');
                }
            });

            return this;
        }

        if (this.elem.classList) {
            this.elem.classList.toggle(className);
        } else {
            let classes = this.elem.className.split(' ');
            let existingIndex = -1;

            classes.forEach((value, index) => {
                if (value === className) {
                    existingIndex = index;
                }
            });

            if (existingIndex >= 0) {
                classes.splice(existingIndex, 1);
            } else {
                classes.push(className);
            }

            this.elem.className = classes.join(' ');
        }


        return this;
    },

    /**
     * Sets or updates the inline style of the selected element or elements.
     *
     * @param {string} key - The name of the CSS property to set or update.
     * @param {string} value - The value of the CSS property to set or update.
     * @returns {Helpers} The current instance of Helpers.
     */
    style: function (key, value) {
        if (this._isElementIterable()) {
            this._iterateElem((elem, index) => elem.style[key] = value);


            return this;
        }

        this.elem.style[key] = value;


        return this;
    },

    /**
    * Gets or sets CSS styles for the selected element(s).
    *
    * @param {string|object} prop - A CSS property name or an object containing property-value pairs.
    * @param {string|undefined} [value] - A CSS value for the given property name.
    * @returns {string[]|string|Helpers}  An array of values if `prop` is a string and multiple elements are selected.
    * The current value of the specified CSS property if prop is a string and a single element is selected.
    * The current instance of `Helpers` if prop is an object and the `style()` method is used for setting styles.
    * The current value of the specified CSS property if prop is an object and the `style()` method is used for getting styles.
    */
    css: function (prop, value = undefined) {
        if (value == undefined) {
            if (typeof prop == 'string') {
                if (this._isElementIterable()) {
                    let temp = [];

                    this._iterateElem((elem, index) => {
                        temp.push(elem.style[prop]);
                    });


                    return temp;
                }


                return this.elem.style[prop]
            } else if (typeof prop === 'object') {
                prop = Object.entries(prop)

                if (this._isElementIterable()) {
                    this._iterateElem((elem, index) => {
                        this._iterate(prop, style => {
                            let styleName = style[0];
                            let styleVal = style[1];

                            elem.style[styleName] = styleVal;
                        })
                    });


                    return this;
                }

                this._iterate(prop, style => {
                    let styleName = style[0];
                    let styleVal = style[1];

                    this.elem.style[styleName] = styleVal;
                })


                return this
            }
        } else {
            if (this._isElementIterable()) {
                this._iterateElem((elem, index) => {
                    elem.style[prop] = value;
                });


                return this;
            }
            this.elem.style[prop] = value;


            return this;
        }


        return this;
    },

    /**
     * Work with element class list and style end
     */

    //---------------------------------------------------

    /**
     * Fade functions start
     */

    /**
     * Display the element(s) by fading them to opaque.
     *
     * @param {number} [delay] - The `delay` (in ms) between `opacity` changing
     * @returns {Helpers} The current instance of `Helpers`.
     */
    fadeIn: function (delay = 30) {
        if (this._isElementIterable()) {
            this._iterateElem((elem, index) => {
                this._fadeTo(elem, { opacityEnd: 1, delay: delay });
            })


            return this;
        }

        this._fadeTo(this.elem, { opacityEnd: 1, delay: delay });


        return this;
    },

    /**
     * Hide the element(s) by fading them to transparent.
     *
     * @param {number} [delay] - The `delay` (in ms) between `opacity` changing
     * @returns {Helpers} The current instance of `Helpers`.
     */
    fadeOut: function (delay = 30) {
        if (this._isElementIterable()) {
            this._iterateElem((elem, index) => {
                this._fadeTo(elem, { opacityEnd: 0, delay: delay });
            })


            return this;
        }

        this._fadeTo(this.elem, { opacityEnd: 0, delay: delay });


        return this;
    },

    /**
     * Adjust the opacity of the element(s).
     *
     * @param {number} opacity - The final elem `opacity`
     * @param {number} [delay] - The `delay` (in ms) between `opacity` changing
     * @returns {Helpers} The current instance of `Helpers`.
     */
    fadeTo: function (opacity, delay = 30) {
        if (this._isElementIterable()) {
            this._iterateElem((elem, index) => {
                this._fadeTo(elem, {
                    opacityEnd: opacity,
                    delay: delay
                });
            })


            return this;
        }

        this._fadeTo(this.elem, {
            opacityEnd: opacity,
            delay: delay
        });


        return this;
    },

    /**
     * Sorry 😥
     * @link https://protocoder.ru/js/fade
     */
    _fadeTo: function (elem, options, dontStartNow) {
        let opacityVal, opacityStart, opacityEnd, opacityStep, t;

        function th() {
            opacityVal += opacityStep;
            if ((opacityStep > 0 && opacityVal >= opacityEnd) || (opacityStep < 0 && opacityVal <= opacityEnd)) {
                opacityVal = opacityEnd;
                clearInterval(t);
                t = null;
            }

            elem.style.opacity = opacityVal;

            if (!t && options.hasOwnProperty("handler")) options.handler(true, fs, elem);
        }

        function init() {
            opacityStep = options.hasOwnProperty("opacityStep") ? Math.abs(options.opacityStep) : 0.1;

            if (!options.hasOwnProperty("delay")) options.delay = 30;

            if (!options.hasOwnProperty("opacityStart")) {
                opacityStart = parseFloat(elem.style.opacity);
                if (isNaN(opacityStart)) opacityStart = 1;
            }
            else {
                opacityStart = options.opacityStart;
                elem.style.opacity = opacityStart;
            }

            opacityVal = opacityStart;

            if (!options.hasOwnProperty("opacityEnd")) {
                opacityEnd = parseFloat(o.style.opacity);
                if (isNaN(opacityEnd)) opacityEnd = 1;
            }
            else opacityEnd = options.opacityEnd;


            if (opacityStart > opacityEnd) opacityStep = -opacityStep;

            if (opacityStart != opacityEnd) t = setInterval(th, options.delay);
        }

        let fs = {
            get: function () {
                return {
                    options: options,
                    opacityVal: opacityVal,
                    opacityEnd: opacityEnd,
                    opacityStep: opacityStep
                };
            },

            stop: function (end, dontNotify) {
                if (!t) return;

                clearInterval(t);
                t = null;
                if (end) o.style.opacity = opacityEnd;

                if (dontNotify !== true && options.hasOwnProperty("handler")) options.handler(true, fs, elem);
            },

            start: function (restart, newOpts, dontNotify) {
                if (t) return;

                if (newOpts) options = newOpts;

                if (restart) {
                    init();
                    if (dontNotify !== true && options.hasOwnProperty("handler")) options.handler(false, fs, elem);
                }
                else t = setInterval(th, options.delay);
            }
        };

        if (dontStartNow !== true) fs.start(true);
        return fs;
    },

    /**
     * Fade functions end
     */

    //---------------------------------------------------

    /**
    * Private helpers functions start
    */

    /**
    * Finds an element or a list of elements using the query string and returns it.
    *
    * @param {string} elem - The query string of the element that needs to be found.
    * @returns {false|HTMLElement|HTMLElement[]} The found element or list of elements, or false if the element cannot be found.
    */
    _find: function (elem) {
        let element = document.querySelectorAll(elem);

        if (element === null) {
            return false;
        }

        if (this._isIterable(element) && element.length > 1) {
            return this._toArray(element);
        }

        return this._toArray(element).slice(-1)[0];
    },

    /**
     *
     * @param {Helpers} elem
     * @returns {Helpers}
     */
    _createHelperFromHelper: function (elem) {
        Object.getOwnPropertyNames(elem).forEach((value, index) => {
            this[value] = elem[value];
        })


        return this;
    },

    /**
     * Checks if the given element is iterable and has multiple elements, then returns it as an array.
     * Otherwise, returns the element as is.
     *
     * @param {HTMLElement|NodeList} elem - The element to check.
     * @returns {HTMLElement|HTMLElement[]} - The checked element, possibly as an array if it was iterable.
     */
    _checkElem: function (elem) {
        if (this._isIterable(elem) && elem.length > 1) {
            return this._toArray(elem);
        }

        return elem;
    },

    /**
    * Calls the provided callback function once for each element in the collection.
    *
    * @param {Function} callback - Function to execute for each element.
    * @returns {undefined}
    */
    _iterateElem: function (callback) {
        return this._iterate(this.elem, callback)
    },

    /**
     * Calls the provided callback function once for each element in the object.
     *
     * @param {Object} obj - The object to iterate over.
     * @param {Function} callback - Function to execute for each element.
     * @returns {undefined}
     */
    _iterate: function (obj, callback) {
        return Array.prototype.forEach.call(obj, (value, index) => {
            callback(value, index);
        });
    },

    _toArray: function (obj) {
        return Array.from(obj);
    },

    /**
     * Check is given object is iterable
     *
     * @param {*} obj - Object to check
     * @returns {boolean}
     */
    _isIterable: function (obj) {
        return typeof obj === 'object' && obj !== null && Symbol.iterator in obj && obj.nodeName != 'FORM';
    },

    /**
     * Check is current `elem` is iterable
     *
     * @returns {boolean}
     */
    _isElementIterable: function () {
        return this._isIterable(this.elem);
    },

    /**
     * Check is given value is boolean type
     *
     * @param {*} value
     * @returns {boolean}
     */
    _isBoolean: function (value) {
        return (value == true || value == false)
    },

    /**
     * This function check, is given element (`elem`) have given `event`
     *
     * @param {HTMLElement} elem - The element, that need to check.
     * @param {string} event - The event, that need to check
     * @returns {boolean}
     */
    _isElementHaveEvent(elem, event) {
        return !!elem[event] || !!elem[event] || !!elem.hasAttribute(event) || !!elem.hasOwnProperty(event)
    },

    /**
    * Private helpers functions end
    */
}
