/**
 *
 * @param {Lang} lang
 * @param {Currency} curr
 * @param {string} baseURL
 * @param {string} baseURLLang
 */
function Domains(lang, curr, baseURL, baseURLLang) {

    this.lang = lang;
    this.curr = curr;
    this.baseURL = baseURL;
    this.baseURLLang = baseURLLang;

    this.maxTryCount = 3
    this.checkDomainAbortTimeout = 20

    this.domainCheckChunkSize = 15

    this.checkDomainUrl = domain => this.baseURL + '/domain-available/' + domain
    this.tableUrl = this.baseURLLang + '/get-table-ajax-domains'
    this.domainBoardUrl = this.baseURLLang + '/get-board-ajax-domain'

    this.domainValidator = new DomainValidation(baseURL, hp('.domain-valid-msg'));

    this.init();
}

Domains.prototype = {
    init: function () {
        if (this.isMainPage()) {
            this.initMainPage();
            return;
        }

        if (this.isDomainMainPage()) {
            this.initDomainMainPage();
            return;
        }

        if (this.isDomainInsidePage()) {
            this.initDomainInsidePage();
            return;
        }
    },

    /**
     * Checks if the current page is the main page - '/'.
     *
     * @return {boolean} Returns true if main-page, otherwise returns false.
     */
    isMainPage: function () {
        try {
            return hp('.header-section').is('.main-page');
        } catch (error) {
            return false
        }
    },

    /**
     * Checks if the current page is the domain main page - '/domains'.
     *
     * @return {boolean} Returns true if the current page is the domain main page, otherwise returns false.
     */
    isDomainMainPage: function () {
        try {
            return hp('.header-section .under-header-section').is('.top-domains');
        } catch (error) {
            return false
        }
    },

    /**
     * Checks if the current page is the domain inside page - '/domains/{domain}'.
     *
     * @return {boolean} Indicates if the domain is inside the page or not.
     */
    isDomainInsidePage: function () {
        try {
            return hp('.header-section .under-header-section').is('.domain-search');
        } catch (error) {
            return false
        }
    },

    /**
     * Initializes the domain main page.
     *
     * This function sets up event listeners for the lower and upper search forms.
     * When the lower form is submitted, it calls the `lowerFormSubmit` function.
     * When the upper form is submitted, it sets the value of the lower form's domain input,
     * calls the `lowerFormSubmit` function, and scrolls to the lower form.
     *
     * @return {void}
     */
    initDomainMainPage: function () {
        let upperSearchForm = hp('#check-domain-upper-page')
        let lowerSearchForm = hp('#check-domain-main')

        if (this.isStaticPage() && this.hasDomainParam()) {
            lowerSearchForm.find('input[name="domain"]').val(this.getDomainFromParam())
        }

        let lowerFormSubmit = () => {
            hp('.main-header-section .reg-domain').hide()
            let triggerBtn = lowerSearchForm.find('.domain-check-submit-btn button')
            let input = lowerSearchForm.find('input[name="domain"]')
            let dump = '';

            this.checkDomain(lowerSearchForm,
                // Before request
                () => {
                    input.prop('disabled', true)
                    dump = triggerBtn.elem.outerHTML
                    triggerBtn.prop('disabled', true)
                    triggerBtn.html('<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>')
                },
                // After request
                () => {
                    input.removeAttr('disabled')
                    triggerBtn.prop('disabled', false)
                    triggerBtn.elem.outerHTML = dump
                    dump = ''
                }
            );
        }

        if (!this.isEmpty(lowerSearchForm.find('input[name="domain"]').val())) {
            lowerFormSubmit()
        }

        lowerSearchForm.on('submit', (e) => {
            e.preventDefault();

            lowerFormSubmit()
        })

        upperSearchForm.on('submit', (e) => {
            e.preventDefault();

            let $this = upperSearchForm

            let domain = $this.find('input[name="domain"]').val().trim()

            if (this.isEmpty(domain)) {
                return;
            }

            lowerSearchForm.find('input[name="domain"]').val(domain)
            lowerFormSubmit()

            window.scrollTo({ top: lowerSearchForm.elem.offsetTop - 100, left: 0, behavior: 'smooth' })
        });

        hp('.domains-filter a').on('click', (e) => {
            e.preventDefault();

            let domain = hp('#domain-input').val().toLowerCase();

            if (this.isEmpty(domain)) {
                location = hp(e.target).attr('href');
                return;
            }

            this.appendDomainToLink(hp(e.target), domain);
        })
    },

    hasDomainParam: function () {
        let params = new URLSearchParams(location.search)
        return params.has('d') || params.has('domain');
    },

    isStaticPage: isStaticPage,

    getDomainFromParam: function () {
        let params = new URLSearchParams(location.search)
        return params.get('d') || params.get('domain');
    },

    appendDomainToLink: function (link, domain) {
        let href = link.attr('href');

        const anchor = href.split('#');

        if (href.indexOf('?') === -1) {
            href = anchor[0] + `?d=${domain}`;
        }

        if (anchor[1]) {
            href += '#' + anchor[1];
        }

        location = href;
    },

    /**
     * Initializes the domain inside page.
     *
     * This function sets up an event listener for the form submission.
     * When the form is submitted, it scrolls to the form, checks the single domain,
     * and prevents the default form submission behavior.
     *
     * @return {void}
     */
    initDomainInsidePage: function () {
        let form = hp('#check-domain-inside')

        if (this.isStaticPage() && this.hasDomainParam()) {
            form.find('input[name="domain"]').val(this.getDomainFromParam())
        }

        let submitForm = () => {
            window.scrollTo({ top: form.elem.offsetTop - 100, left: 0, behavior: 'smooth' })

            this.checkSingleDomain(form)
        }

        if (!this.isEmpty(form.find('input[name="domain"]').val())) {
            submitForm()
        }

        form.on('submit', (e) => {
            e.preventDefault()
            submitForm()
        })

        form.find('input[name="domain"]').on('blur', (e) => {
            const domain = this.getDomainName(form.find('input[name="domain"]').val());
            const zone = hp('.domain-inside').find('.zone').val(); //get domain zone

            if (this.isEmpty(domain)) {
                return
            }

            //set domain
            if (domain && !domain.endsWith(zone)) {
                form.find('input[name="domain"]').val(domain + zone)
            }
        })

        hp('#check-domains-in-other-zones').on('click', (e) => {
            e.preventDefault()

            let domain = form.find('input[name="domain"]').val().split('.')[0].toLowerCase().trim()

            if (this.isEmpty(domain)) {
                return;
            }

            this.redirectToDomainPage(domain)
        })
    },

    /**
     * Initializes the main page for domain checking.
     *
     * @return {void}
     */
    initMainPage: function () {
        hp('#domain-check-main-page').on('submit', (e) => {
            e.preventDefault()
            let $this = hp('#domain-check-main-page')

            let domain = $this.find('input[name="domain"]').val().trim()

            if (this.isEmpty(domain)) {
                return;
            }

            this.redirectToDomainPage(domain)
        })
    },

    /**
     * Checks if the given item is empty.
     *
     * @param {any} item - The item to check for emptiness.
     * @return {boolean} Returns true if the item is null, empty string, undefined, or has a length of 0, otherwise returns false.
     */
    isEmpty: function (item) {
        return item == null || item == '' || item == undefined || item.length === 0;
    },

    /**
     * Redirects the user to the domain page by appending the domain to the URL.
     *
     * @param {string} domain - The domain to redirect to.
     * @return {void} This function does not return anything.
     */
    redirectToDomainPage: function (domain) {
        location = `${this.baseURLLang}/domains?d=${domain}`
    },

    checkDomain: function (form, beforeRequest = () => { }, afterRequest = () => { }) {
        let domainInput = form.find('input[name="domain"]')
        const filter = hp('.domains-filter').find('.active').data('filter').trim(); //set category filter
        const domain = domainInput.val().toLowerCase();
        const obj = {};

        if (!this.isEmpty(filter)) {
            obj.filter = filter;
        }

        if (!this.domainValidator.validateDomainName(domain)) {
            domainInput.addClass('error'); //add error class for domain input
            return false;
        } else {
            domainInput.removeClass('error'); //remove error class for domain input
        }

        if (domain) {
            obj.domain = domain.split('.')[0];
            if (domain.indexOf('.') !== -1) {
                obj.zone = domain.substr(domain.indexOf('.'));
            }
        }

        if (obj.domain && obj.zone) {
            this.sendAjaxBoard(obj, form, beforeRequest, afterRequest);
            window.scrollTo({ top: form.elem.offsetTop - 100, left: 0, behavior: 'smooth' })
        } else {
            hp('.board').hide()

            window.scrollTo({ top: hp('.domain-table').elem.offsetTop - 100, left: 0, behavior: 'smooth' })
        }

        this.sendAjaxTable(obj);
    },

    checkSingleDomain: function (from) {
        const domain = this.getDomainName(from.find('input[name="domain"]').val());
        const zone = from.find('.zone').val();

        if (domain.length > 1) {
            from.find('.domain-input').val(domain + zone)

            //validate domain
            if (!this.domainValidator.validateDomainName(domain + zone)) {
                from.find('.domain-input').addClass('error')
                return false;
            }

            from.find('.domain-input').removeClass('error')

            let triggerBtn = from.find('.domain-check-submit-btn button')
            let dump = '';

            this.sendAjaxBoard({ domain: domain, zone: zone }, from,
                () => {
                    dump = triggerBtn.elem.outerHTML
                    triggerBtn.prop('disabled', true)
                    triggerBtn.html('<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>')
                },
                () => {
                    triggerBtn.prop('disabled', false)
                    triggerBtn.elem.outerHTML = dump
                    dump = ''
                }
            );
        } else {
            hp('.board').hide()
        }
    },

    sendAjaxBoard: function (data, form, beforeRequest = () => { }, afterRequest = () => { }) {
        beforeRequest();

        fetch(this.domainBoardUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-TOKEN': hp('meta[name="csrf-token"]').attr('content')
            },
            body: JSON.stringify(data)
        }).then((result) => {
            if (result.ok) {
                return result.json()
            }

            return {
                result: 'error'
            }
        }).then((res) => {
            if ('result' in res && res.result == 'error') {
                return;
            }

            if (res.hasOwnProperty('domaininfo') && res['domaininfo']['idn'] == 0 && res['checkedIdn'] == false) {
                // No available entry, see ToolsController@checkDomain
                this.drawBoard('noavailable', res, data);
            } else if (res.hasOwnProperty('domaininfo')) {
                if (res['domainAvail']) {
                    this.drawBoard('available', res, data);
                } else {
                    this.drawBoard('registered', res, data);
                }
            } else {
                hp('.board').hide()
            }
        }).then(() => {
            this.curr.editCurrency()

            afterRequest();
        })
    },

    drawBoard: function (status, res, data) {
        let board = '';
        const price = res['domaininfo']['usd']; //domain price
        const langs = { ru: 'russian', en: 'english', ua: 'ukrainian' }; //biling language

        let board_template = document.getElementById('board-template')
        let board_instance = board_template.content.cloneNode(true)

        let boardInstance = hp(board_instance)

        boardInstance.find('slot[name="searched-domain"]').text(data.domain + data.zone)

        boardInstance.find('slot[name="price"] .price').data('currency', price)
        boardInstance.find('slot[name="price"] .watermark').text(this.lang.getInterpreter()[this.lang.getSiteLang()][this.curr.getCurrency()])
        boardInstance.find('slot[name="price"] .sufix').text(' / ' + this.lang.getInterpreter()[this.lang.getSiteLang()]['year-1'])

        if (status === 'registered') {
            boardInstance.find('slot[name="domain-status"]').text(
                this.lang.getInterpreter()[this.lang.getSiteLang()]['busy']
            )

            boardInstance.find('.search-status').addClass('text-danger')

            let btn = `<a class="button button-link" href="https://hyperhost.ua/client/cart.php?a=add&domain=transfer&language=${langs[this.lang]}">${this.lang.getInterpreter()[this.lang.getSiteLang()]["move"]}</a></li></ul>`

            boardInstance.find('slot[name="order-button"]').html(btn)

            board = board_instance.children
        } else if (status === 'noavailable') {
            boardInstance.find('slot[name="domain-status"]').text(this.lang.getInterpreter()[this.lang.getSiteLang()]["no-available"])
            boardInstance.find('.search-status').addClass('text-danger')
        } else { //if available for registration

            hp(board_instance).find('slot[name="domain-status"]').text(this.lang.getInterpreter()[this.lang.getSiteLang()]['free'])

            hp(board_instance).find('.search-status').addClass('free')

            hp(board_instance).find('slot[name="order-button"]').html(
                '<form action="' + this.baseURLLang + '/cart" method="post">' +
                '<input type="hidden" name="type" value="domain">' +
                '<input type="hidden" name="domain" value="' + data.domain + '">' +
                '<input type="hidden" name="id" value="' + res['domaininfo']['id'] + '">' +
                '<input type="hidden" name="_token" value="' + hp('meta[name="csrf-token"]').attr('content') + '">' +
                '<input class="button button-link" type="submit" value="' + this.lang.getInterpreter()[this.lang.getSiteLang()]["order"] + '"></form></li></ul>'
            )

            board = board_instance.children
        }

        try {
            hp('.board').html(board[0].outerHTML).show()
        } catch (error) {

        }
    },

    checkAvailableAllDomains: function () {
        const rows = hp('.domain-table tbody tr');

        //Dont need to fire function if table is null, helper.js return null for elem if it not founded
        if (rows.elem === null) {
            return
        }

        rows.chunk(this.domainCheckChunkSize, (chunk, chunkIndex) => {
            chunk = hp(chunk)
            let domains = []
            let tryCount = 0;

            chunk.each((elem) => {
                let domain = hp(elem).children().eq(0).find('.domain-cell').html().trim()
                domains.push(domain)
            })

            let fetchData = () => {
                fetch(this.baseURL + '/domains-available', {
                    method: 'POST',
                    headers: {
                        'X-CSRF-TOKEN': hp('meta[name="csrf-token"]').attr('content'),
                        'Content-Type': 'application/json',
                        'Accept': 'application/json'
                    },
                    body: JSON.stringify({ 'domains': domains }),
                }).then((result) => {
                    if (result.status == 504 && tryCount < this.maxTryCount) {
                        tryCount = tryCount + 1;
                        console.error(`Try fetch domain chunk count ${tryCount} of ${this.maxTryCount}. Reason: Timeout`);
                        fetchData();

                        return {
                            result: 'error'
                        }
                    }


                    return result.json()
                }).then((res) => {
                    if ('result' in res && res.result == 'error') {
                        chunk.each(elem => {
                            let status = hp(elem).children().eq(2)
                            let link = hp(elem).children().eq(6).children()

                            status.html(this.lang.getInterpreter()[this.lang.getSiteLang()]['no-available']);
                            status.addClass('text-danger')
                            link.parent('td').html('—');
                            return
                        });

                        return
                    }

                    if (res.hasOwnProperty('response') && res['response'].hasOwnProperty('domains') && res['response']['domains'] != false) {
                        let domainsResult = res['response']['domains']

                        chunk.each(elem => {
                            let status = hp(elem).children().eq(2)
                            let domain_cell = hp(elem).children().eq(0).find('.domain-cell')
                            let domain = hp(elem).children().eq(0).find('.domain-cell').html().trim()
                            let link = hp(elem).children().eq(6).children()

                            if (domainsResult.hasOwnProperty(domain) && domainsResult[domain] == true) {
                                status.html(this.lang.getInterpreter()[this.lang.getSiteLang()]['free']);
                                domain_cell.html(this.generateUrlForInsideDomain(domain))
                                status.addClass('available')
                                link.show()
                            } else {
                                status.html('<span class="text-danger">' + this.lang.getInterpreter()[this.lang.getSiteLang()]['busy'] + '</span>');
                                domain_cell.addClass('text-black-50')
                                link.parent('td').html('—');
                            }
                        });

                        return
                    }

                    chunk.each(elem => {
                        let status = hp(elem).children().eq(2)
                        let link = hp(elem).children().eq(6).children()
                        let domain_cell = hp(elem).children().eq(0).find('.domain-cell')

                        status.html('<span class="text-danger">' + this.lang.getInterpreter()[this.lang.getSiteLang()]['busy'] + '</span>');
                        domain_cell.addClass('text-black-50')
                        link.parent('td').html('—');
                        return
                    });
                }).then(() => {
                    this.curr.editCurrency();
                }).catch((error) => {
                    if (tryCount != this.maxTryCount) {
                        tryCount = tryCount + 1;
                        console.error(`Try fetch domain chunk count ${tryCount} of ${this.maxTryCount}. Reason: Timeout`);
                        fetchData();
                        return;
                    }

                    chunk.each(elem => {
                        let status = hp(elem).children().eq(2)
                        let link = hp(elem).children().eq(6).children()
                        let domain_cell = hp(elem).children().eq(0).find('.domain-cell')

                        status.html('<span class="text-danger">' + this.lang.getInterpreter()[this.lang.getSiteLang()]['no-available'] + '</span>');
                        domain_cell.addClass('text-black-50')
                        link.parent('td').html('—')
                    });
                })
            }

            fetchData();
        })
    },

    sendAjaxTable: function (data) {
        fetch(this.tableUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-TOKEN': hp('meta[name="csrf-token"]').attr('content')
            },
            body: JSON.stringify(data)
        }).then((result) => {
            if (result.ok) {
                return result.json()
            }


            return { result: 'error' }
        }).then((res) => {
            this.refreshTable(res.view, '.domain-table tbody');

            if (data.domain) {
                this.checkAvailableAllDomains(data)
            }

            this.recastTooltips()
        })
    },

    recastTooltips: function () {
        let labelsBlock = hp('.domain-table tbody tr .labels');

        labelsBlock.each((block, i) => {
            let idnLabel = hp(block).find('.label.idn')

            if (!idnLabel.isEmpty()) {
                createTooltip(idnLabel.elem, i)
            }
        })
    },

    generateUrlForInsideDomain: function (domain) {
        let link = domain.split('.')
        link.shift()
        let domain_zone = link.join('.')
        let url = `<a href="/${this.lang.getSiteLang()}/domains/${domain_zone}?domain=${domain}">${domain}</a>`

        return url;
    },

    refreshTable: function (view, table) {
        hp(table).html(view)
    },

    getDomainName: function (domain) {
        if (domain.length < 1) {
            return '';
        }

        return domain.split('.')[0].toLowerCase().trim()
    }
}

/**
 * Domain Validator Module
 * Used on all pages where domain checking is available
 * Works as Middleware
 *
 * @param {string} baseURL
 * @param {HTMLElement|string|Helpers|null} out_elem
 */
function DomainValidation(baseURL, out_elem = null) {

    this.out_elem = out_elem;
    const self = this;

    // ajax, server validation
    this.validateDomainName = function (domain_name, zone_necessary = false, callback = undefined) {

        let is_valid = true;

        let body = JSON.stringify({
            'domain': domain_name,
            'lang': window.LANG,
            'zone_necessary': zone_necessary
        })

        let request = new XMLHttpRequest()

        request.onload = () => {
            is_valid = true
            let res = JSON.parse(request.response)

            if (typeof res.success !== 'undefined' && res.success === false) {
                is_valid = false
                hp(self.out_elem).html(res.forbidden)
                hp('.board').html('')
                return
            }

            hp(self.out_elem).html('')

        }

        request.onerror = () => {
            is_valid = false
        }

        request.open('POST', baseURL + '/valid-domain-name', false)
        request.overrideMimeType('application/json')
        request.setRequestHeader('X-CSRF-TOKEN', hp('meta[name="csrf-token"]').attr('content'))
        request.setRequestHeader('Content-Type', 'application/json')
        request.send(body)

        return is_valid
    };

    this.setOutElem = function (new_elem) {
        this.out_elem = new_elem;
    };

}

function domains() {
    return new Domains(...arguments);
};

module.exports = { domains, DomainValidation };
