import { Tooltip, Modal, Tab, Collapse } from 'bootstrap';
import 'sortable-tablesort/sortable.min.js';
import { helpers, getCookie, setCookie } from './helpers';
import CSRF from './modules/CSRF';
import Cookies from './modules/Cookies';
import AdmitadLCW from './modules/AdmitadLCW';

window.Cookies = Cookies;
window.cookies = (...args) => Cookies.cookies(...args);
window.bootstrap = {
    Tooltip: Tooltip,
    Modal: Modal,
    Tab: Tab,
    Collapse: Collapse
};
window.createTooltip = (elem) => {
    let arrowColor = elem.getAttribute('data-tooltip-color') ?? 'question';
    let icon = elem.getAttribute('data-tooltip-icon') ?? '?';

    if (!!elem.getAttribute('data-bs-title')) {
        new bootstrap.Tooltip(elem, {
            template: `
            <div class="tooltip" role="tooltip">
                <div class="tooltip-arrow ${arrowColor}"></div>
                <div class="tooltip-text-wrapper">
                    <div class="tooltip-icon ${arrowColor}">
                        ${icon}
                    </div>
                    <div class="tooltip-inner">/div>
                </div>
            </div>
            `,
            offset: ({ placement, reference, popper }) => {
                if (placement == 'top' || placement == 'bottom') {
                    let iconWidth = 40;
                    let textWidth = popper.width - iconWidth;

                    let skidding = textWidth / 2;

                    let distance = 5

                    if (placement == 'bottom') {
                        distance = 10
                    }

                    return [skidding, distance]
                }

                if (placement == 'left' || placement == 'right') {
                    return [3, 10]
                }

                return []
            }
        })
    }
};
document.querySelectorAll('[data-bs-toggle="tooltip"]').forEach((elem) => createTooltip(elem));

window.helpers = window.hp = helpers;
window.getCookie = getCookie;
window.setCookie = setCookie;
window.isStaticPage = () => hp('html').is('[data-static]');

window.CSRF = CSRF
window.onload = () => CSRF.onloadEvent()
window.AdmitadLCW = AdmitadLCW;

require('./modules/Rating');
require('./partials/bootstrap');
require('./main-site')
require('./coockies');
require('./includes/table');
