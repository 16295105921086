'use strict';

class Cookies {
    static cookies(key, value = undefined, days = 30) {
        if (value == undefined || ('' + value).length == 0) {
            return Cookies.#getCookie(key);
        }

        return Cookies.#setCookie(key, value, days);
    }

    static removeCookie(key) {
        return Cookies.#setCookie(key, '', -1);
    }

    static #getCookie(key) {
        if (key == '') {
            return document.cookie.split(';').map(cookie => cookie.trim().split('='));
        }

        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${key}=`);

        if (parts.length === 2) {
            return parts.pop().split(';').shift()
        };

        return null;
    }

    static #setCookie(key, value, days) {
        const time = Cookies.calcDaysToMilliseconds(days);

        document.cookie = `${key}=${value};expires=${time};path=/`;
    }

    static calcDaysToMilliseconds(days) {
        const d = new Date();
        d.setTime(d.getTime() + (days * 24 * 60 * 60 * 1000));


        return d.toUTCString();
    }
}

module.exports = Cookies;
