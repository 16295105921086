import { lang } from "../varaibles";
// import intlTelInput from 'intl-tel-input';


let data = [];
let validation = {};

validation.counter = function (val, min, max) {
    if (isNaN(val)) {
        return min;
    } else if (val < min) {
        return min;
    } else if (val > max) {
        return max;
    }
    return val;
};

validation.email = function (arg) {
    const re = /[A-Z0-9._%+-]+@[A-Z0-9.-]+.[A-Z]{2,4}/igm;
    return re.test(arg);
};

validation.password = function (arg) {
    return !(arg.length > 5 && arg.length < 255);
};

validation.phone = function (arg) {
    const re = /^([0-9]{3,25})*$/;
    return re.test(arg);
};

validation.index = function (arg) {
    const pattern = /[0-9]/;
    return pattern.test(arg);
};

// Create a new password
hp('.password-generation').on('click', function (e) {
    e.preventDefault();
    const field = hp('.password-generation').prev();
    const pass = randString(field); //new password
    field.val(pass).attr('type', 'text'); //set new password & show
    hp('[name="repeat-password-reg"]').val(pass).attr('type', 'text'); //set new password & show
    checkStrength(pass);
});

// Generate a password string
function randString(item) {
    const dataSet = hp(item).data('character-set').split(',');
    let possible = '';

    if (dataSet.includes('a-z')) {
        possible += 'abcdefghijklmnopqrstuvwxyz';
    }

    if (dataSet.includes('A-Z')) {
        possible += 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    }

    if (dataSet.includes('0-9')) {
        possible += '0123456789';
    }

    if (dataSet.includes('#')) {
        possible += '!"#$%&\'()*+,-./:;<=>?@[]^_`{|}~ '; // space in end of string is ok
    }

    let text = '';

    for (let i = 0; i < hp(item).data('size'); i++) {
        text += possible.charAt(Math.floor(Math.random() * possible.length));
    }

    return text;
}

function checkStrength(password) {
    let strength = 0;
    let color = '#c10001';

    if (password.length > 5) {
        strength += 1;
    }

    // If password contains both lower and uppercase characters, increase strength value.
    if (password.match(/([a-zа-я].*[A-ZА-Я])|([A-ZА-Я].*[a-zа-я])/)) {
        strength += 1;
    }

    // If it has numbers and characters, increase strength value.
    if (password.match(/([a-zA-Zа-яА-Я])/) && password.match(/([0-9])/)) {
        strength += 2;
    }

    // If it has one special character, increase strength value.
    if (password.match(/([-+!,&@#$^*?_~])/)) {
        strength += 2;
    }

    // If it has two special characters, increase strength value.
    if (password.match(/(.*[-+!,&@#$^*?_~].*[-+!,&@#$^*?_~])/)) {
        strength += 4;
    }

    if (strength == 10) {
        color = '#0eacbd';
    } else if (strength >= 6) {
        color = '#00b22c';
    } else if (strength >= 4) {
        color = '#ffd221';
    } else if (strength >= 2) {
        color = '#ff8f00';
    }

    hp('#progress-bar_password_strength .progress-bar').attr('aria-valuenow', strength * 10 + '%').css({
        'width': strength * 10 + '%',
        'background': color
    });
}

// choose buyer
// hp(' .modal-header ul input').on('change', function () {
//     hp(' .modal-header ul input').parents('ul').children().removeClass('active');
//     hp(' .tab-content .tab').removeClass('active');

//     hp(' .modal-header ul input').parents('li').addClass('active');
//     hp(' .tab-content #' + hp(' .modal-header ul input').data('tab')).addClass('active');
// });

//choose personal | organization
let regForm = hp('#reg_form')

if (!regForm.isEmpty()) {
    regForm.find('input[name="object"]').on('change', (e) => {
        let target = hp(e.target)

        if (target.val() == 'organization') {
            hp('#organization-block').addClass('active')
        } else if (target.val() == 'individual') {
            hp('#organization-block').removeClass('active')
        }
    })
    regForm.on('submit', (e) => {
        e.preventDefault();

        sendRegForm();
    })
}

let logForm = hp('#log_form')

if (!logForm.isEmpty()) {

    logForm.on('submit', (e) => {
        e.preventDefault();

        sendLoginForm()
    })
}

//change type of password
hp('.custom-input-placeholder.after > .hypericon-eye-slash-solid').on('click', (e) => {
    let targetBtn = hp(e.target)

    let form = targetBtn.parentsUntil('.input-section').last()
    let passwordInput = form.find('input[type="password"]')

    if (passwordInput.isEmpty()) {
        passwordInput = form.find('input[data-character-set="a-z,A-Z,0-9,#"]')
    }

    if (passwordInput.isEmpty()) {
        passwordInput = form.find('input[name="password"]')
    }

    if (targetBtn.hasClass('hypericon-eye-slash-solid')) {
        passwordInput.attr('type', 'text')
        targetBtn
            .removeClass('hypericon-eye-slash-solid')
            .addClass('hypericon-eye-solid')
    } else {
        passwordInput.attr('type', 'password')
        targetBtn
            .addClass('hypericon-eye-slash-solid')
            .removeClass('hypericon-eye-solid')
    }
})

let sendRegForm = () => {
    let form = hp('#reg_form')
    let submitBtn = form.find('button[type="submit"]')
    let formData = form.serializeArray()
    let data = {
        buyer: 'new',
        language: hp('html').attr('lang')
    };

    submitBtn.prop('disabled', true)

    formData.forEach(inputData => {
        if ('name' in inputData && 'value' in inputData) {
            data[inputData.name] = inputData.value
        }
    })

    if (data['object'] == 'individual') {
        delete data.company;
        delete data.country;
        delete data.pib;
        delete data.address;
        delete data.posada;
        delete data.city;
        delete data.iban;
        delete data.region;
        delete data.edrpou;
        delete data.index;
    }

    if (!validationSendData(form, data)) {
        submitBtn.removeAttr('disabled')
        return;
    }

    authRequest(data, form, submitBtn)
}

let sendLoginForm = () => {
    let form = hp('#log_form')
    let submitBtn = hp('#order-with-login')
    let formData = form.serializeArray()
    let data = {
        buyer: 'old',
        language: hp('html').attr('lang')
    };

    submitBtn.prop('disabled', true)

    formData.forEach(inputData => {
        if ('name' in inputData && 'value' in inputData) {
            data[inputData.name] = inputData.value
        }
    })

    if (!validationSendData(form, data)) {
        submitBtn.removeAttr('disabled')
        return;
    }

    authRequest(data, form, submitBtn)
}

let authRequest = (data, form, submitBtn) => {
    let request = new XMLHttpRequest()
    let errorBlock = form.find('.error-block')
    let errorMsgBlock = errorBlock.find('.error-msg')

    errorBlock.addClass('d-none')

    request.onload = () => {
        let response = JSON.parse(request.response)

        if (typeof response === typeof '') {
            response = JSON.parse(response);
        }

        if (response['result'] === 'error') {
            errorBlock.removeClass('d-none')
            errorMsgBlock.text(response['message'])
        }

        if (response['result'] === 'success') {
            window.location.replace(response['redirect_url']);
        }

        submitBtn.removeAttr('disabled')
    }

    request.onerror = () => {
        console.error(request.response)
        submitBtn.removeAttr('disabled')
    }

    request.open('POST', `https://hyperhost.ua/client/login-register.php`, false)
    request.overrideMimeType('application/x-www-form-urlencoded')
    request.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded')
    request.send(new URLSearchParams([...Object.entries(data)]).toString())
}

function validationSendData(form, data) {
    let errorBlock = form.find('.error-block')
    let errorMsgBlock = errorBlock.find('.error-msg')

    errorBlock.addClass('d-none')

    let showError = (msg = '') => {
        errorBlock.removeClass('d-none');
        errorMsgBlock.text(msg);
    }

    if (data.email.length <= 0 || !validation.email(data['email'])) {
        showError(lang.getInterpreter()[lang.getSiteLang()]['valid-email'])

        return false;
    }

    if (data.buyer == 'old') {
        if (data.password.length <= 0 || data.password.length < 2) {
            showError(lang.getInterpreter()[lang.getSiteLang()]['valid-password-old'])

            return false;
        }
    }

    if (data.buyer == 'new') {
        if (['', null, undefined].includes(data.firstname.trim()) || (data.firstname.length <= 0 || data.firstname.length > 255)) {
            showError(lang.getInterpreter()[lang.getSiteLang()]['valid-name'])

            return false;
        }

        if (['', null, undefined].includes(data.lastname.trim()) || (data.lastname.length <= 0 || data.lastname.length > 255)) {
            showError(lang.getInterpreter()[lang.getSiteLang()]['valid-last-name']);

            return false;
        }

        if (['', null, undefined].includes(data.phone) || (data.phone.length <= 0 || !validation.phone(data.phone))) {
            showError(lang.getInterpreter()[lang.getSiteLang()]['valid-phone']);

            return false;
        }

        if (['', null, undefined].includes(data.password) || (data.password.length <= 0 || validation.password(data.password))) {
            showError(lang.getInterpreter()[lang.getSiteLang()]['valid-password'])

            return false;
        }

        if (data.password !== data['repeat-password-reg']) {
            showError(lang.getInterpreter()[lang.getSiteLang()]['valid-repeat-password']);

            return false;
        }

        if (data['object'] == 'organization') {
            if (['', null, undefined].includes(data.company) || (data.company.length <= 0 || data.company.length > 255)) {
                showError(lang.getInterpreter()[lang.getSiteLang()]['valid-company'])

                return false;
            }

            if (['', null, undefined].includes(data.address) || (data.address.length <= 0 || data.address.length > 255)) {
                showError(lang.getInterpreter()[lang.getSiteLang()]['valid-address'])

                return false;
            }

            if (['', null, undefined].includes(data.city) || (data.city.length <= 0 || data.city.length > 255)) {
                showError(lang.getInterpreter()[lang.getSiteLang()]['valid-city'])

                return false;
            }

            if (['', null, undefined].includes(data.region) || (data.region.length <= 0 || data.region.length > 255)) {
                showError(lang.getInterpreter()[lang.getSiteLang()]['valid-region'])

                return false;
            }

            if (['', null, undefined].includes(data.region) || (data.region.length <= 0 || data.region.length > 255)) {
                showError(lang.getInterpreter()[lang.getSiteLang()]['valid-region'])

                return false;
            }

            if (['', null, undefined].includes(data['index']) || (data['index'].length <= 0 || !validation.index(data['index']))) {
                showError(lang.getInterpreter()[lang.getSiteLang()]['valid-index'])

                return false;
            }
        }
    }

    return true;
}
