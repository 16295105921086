hp(window).on('load', (e) => {
    const selects = hp('select.custom-select')

    try {
        selects.elem.matches('select')
    } catch (error) {
        selects.each((select, index) => {
            createFakeSelect(hp(select), select, select.classList.value)
        })

        return
    }

    createFakeSelect(selects)
})

let createFakeSelect = (options, elementToPlace = undefined, classes = 'custom-select') => {
    const optionsArr = hp(options)

    const fakeSelect = hp(document.createElement('div'))

    const optionsListWrapper = hp(document.createElement('div')).addClass('option-list-wrapper')

    const fakeOptionsList = hp(document.createElement('ul')).addClass('option-list')

    let selectedOptionText = '';

    optionsArr.each((option, i) => {
        option = hp(option)

        if (option.attr('selected') !== null) {
            selectedOptionText = option.text()
        }

        let attributes = option.elem.attributes

        let resultAttributes = `class="option" data-option-value="${option.val()}" data-option-is-selected="${option.attr('selected') !== null}"`

        Array.from(attributes).forEach((attribute, index) => {
            resultAttributes += ` ${attribute.name}="${attribute.value}"`
        })

        fakeOptionsList.append(
            `<li ${resultAttributes}>${option.text()}</li>`
        )
    })

    if (selectedOptionText == '') {
        selectedOptionText = hp(optionsArr.elem).first().text()
    }

    optionsListWrapper.append(fakeOptionsList.elem.outerHTML)

    fakeSelect.addClass(classes)
        .append(`<span class="title">${selectedOptionText}</span>`)
        .append(optionsListWrapper.elem.outerHTML)

    createEventForSelect(fakeSelect, optionsArr)

    if (elementToPlace != undefined) {
        elementToPlace.after(fakeSelect.elem)

        return
    }

    options.elem.after(fakeSelect.elem)
}

let createEventForSelect = (select, originOptions) => {
    const FAKE_SELECT = hp(select)
    const FAKE_SELECT_TITLE = FAKE_SELECT.find('.title')
    const FAKE_OPTIONS = FAKE_SELECT.find('.option')
    const ORIGIN_SELECT = hp(hp(originOptions).elem).parent()

    FAKE_SELECT.on('click', (e) => {
        toggleOptions(FAKE_SELECT.find('.option-list-wrapper'))
    })

    FAKE_OPTIONS.on('click', (e) => {
        let targetOption = hp(e.target)

        if (targetOption.elem.hasAttribute('disabled') || targetOption.attr('disabled') == 'true') {
            return;
        }

        FAKE_SELECT_TITLE.text(targetOption.text())

        // change selected option in fake select
        FAKE_SELECT.find('[data-option-is-selected="true"]').prop('data-option-is-selected', false)
        targetOption.prop('data-option-is-selected', true)

        // change selected option in origin select
        ORIGIN_SELECT.find('[selected]').removeAttr('selected')
        ORIGIN_SELECT.find(`[value="${targetOption.data('option-value')}"]`).attr('selected', '')
    })
}

let toggleOptions = (options) => {
    hp(options).toggle('show')
}
