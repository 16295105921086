//COOKIE POLICY

hp(window).on('load', () => {
    if (hp('html').attr('lang') != 'en') {
        return;
    }

    const GDPR = hp('#gdpr')

    if (getCookie('gdpr') == 'true') {
        return;
    }

    GDPR.removeClass('d-none')

    GDPR.find('.close-gdpr').on('click', (e) => {
        e.preventDefault();

        setCookie('gdpr', true, { expires: 356, path: '/' })

        GDPR.addClass('d-none')
    })
})
